import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  getEntityDepositMethods,
  getEntityDepositTypes,
  getAllEntities,
  getEntitiesIdByUserId,
} from './DepositsMiddle';
import { insertCreditDeposit } from '../../_GlobalFunctions/accountingFunctions';

const CreditForm = ({ userId = 54155 }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({ user_id: userId, deposit_amount: '' });
  const [methods, setMethods] = useState([]);
  const [entities, setEntities] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [types, setTypes] = useState([]);

  //deposit amount is creating an error message when filling the field for the first time

  useEffect(() => {
    const fetchData = async () => {
      const getMethods = await getEntityDepositMethods();
      const getTypes = await getEntityDepositTypes();
      const getEntities = await getAllEntities();
      const getBrands = await getEntitiesIdByUserId(userId);
      setMethods(getMethods);
      setTypes(getTypes);
      setEntities(getEntities);
      setVendors(getEntities.filter((e) => getBrands.includes(e.entity_id)));
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    insertCreditDeposit(formData);
    setFormData({
      deposit_amount: '',
      description: '',
      entity_deposit_method_id: '',
      entity_deposit_type_id: '',
      entity_id: '',
      notes: '',
      user_id: userId,
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 6,
          marginBottom: 6,
          borderRadius: '10px',
          padding: 1,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: '16px',
          padding: '16px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
          border: '1px solid rgba(0, 0, 0, 0.1)', // Light border for definition
        }}
      >
        <Typography
          align='center'
          sx={{
            fontSize: '2em',
            color: theme.palette.primary.main,
            gridColumn: { sm: 'span 2' },
          }}
        >
          Deposit Form
        </Typography>
        <TextField
          label='Vendors'
          name='entity_id' // Match the key in formData
          value={formData.entity_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {vendors.map((e, index) => (
            <MenuItem key={index} value={e.entity_id}>
              {e.business_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Deposit Type'
          id='entity_deposit_type_id'
          name='entity_deposit_type_id' // Match the key in formData
          value={formData.entity_deposit_type_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {types.map((e, index) => (
            <MenuItem key={index} value={e.entity_deposit_type_id}>
              {e.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Deposit Method'
          id='deposit_method'
          name='entity_deposit_method_id' // Match the key in formData
          value={formData.entity_deposit_method_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {methods.map((e, index) => (
            <MenuItem key={index} value={e.entity_deposit_method_id}>
              {e.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Deposit Amount'
          variant='outlined'
          name='deposit_amount'
          value={formData.deposit_amount}
          onChange={(e) => {
            if (/^\d*\.?\d{0,2}$/.test(e.target.value)) handleChange(e);
          }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            },
          }}
        />

        <TextField
          label={formData.entity_deposit_method_id === 1 ? 'Credit Memo Id' : 'Description'}
          disabled={!formData.entity_deposit_method_id}
          variant='outlined'
          name='description'
          value={formData.description || ''}
          onChange={handleChange}
          sx={{ gridColumn: { sm: 'span 2' } }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          multiline
          rows={3}
          label='Notes'
          variant='outlined'
          name='notes'
          value={formData.notes || ''}
          onChange={handleChange}
          sx={{ gridColumn: { sm: 'span 2' } }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <Box sx={{ textAlign: 'center', gridColumn: { sm: 'span 2' } }}>
          <Button
            sx={{ bgcolor: theme.palette.save.main, width: '30%' }}
            variant='contained'
            onClick={handleSubmit}
            disabled={
              !formData.deposit_amount ||
              !formData.entity_deposit_method_id ||
              !formData.entity_deposit_type_id ||
              !formData.entity_id ||
              !formData.user_id ||
              !formData.description ||
              formData.deposit_amount <= 0
            }
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

//entity_deposit_id, entity_id, entity_deposit_type_id, entity_deposit_method_id, user_id, description, deposit_amount, notes, time_created, deleted, version
//entity_deposit_type_id, entity_deposit_method_id, description, deposit_amount, notes

export default CreditForm;
