import React, { useContext, useEffect, useState } from 'react';
//import { EntityContactContextState } from '../../../../Context/Entity/EntityContacts/EntityContactContext';
import { EntityContactContextState } from '../../../../Context/Marketing/EntityContactContext';
import { ContractContextState } from '../../../../Context/Marketing/ContractContext';

// TABLE COLUMNS ELEMENTS
import useColumns from './components/EntityContactsTableColumns';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from 'material-react-table';

// MUI ELEMENTS
import { Box, Button, IconButton, useTheme } from '@mui/material';
import CreateNewContact from './components/CreateNewContact';
import {
  AttachMoneyOutlined,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DirectionsRunOutlined,
  Diversity3,
  EmailOutlined,
  HomeOutlined,
  KitesurfingOutlined,
  LocationOffOutlined,
  MarkEmailReadOutlined,
  MoneyOffCsredOutlined,
  NotAccessibleOutlined,
  NotListedLocationOutlined,
  PersonOffOutlined,
  PhoneDisabledOutlined,
  PhoneIphoneOutlined,
  PhoneOutlined,
  RoomOutlined,
  UnsubscribeOutlined,
} from '@mui/icons-material';

// DEFAULT COMPONENT
const EntityContactsTable = () => {
  // INITs
  const theme = useTheme();
  const columns = useColumns(); // COLUMNS FOR TABLE

  // CONTEXT STATE
  const { allEntityContacts, allEmails, allPhoneNumbers, allAssociationLink } =
    useContext(EntityContactContextState);
  const { allContracts } = useContext(ContractContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableDataState, setTableDataState] = useState([]);
  const [activeOnly, setActiveOnly] = useState(true);
  const [filters, setFilters] = useState({
    active: 1,
    address: 3,
    email: 3,
    phone: 3,
    hasAssociations: 3,
    hasContracts: 3,
  });

  // TABLE OPTIONS
  const table = useMaterialReactTable({
    columns,
    data: tableDataState,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    initialState: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 100 },
      showGlobalFilter: true,
      //columnOrder: ['entity_id', 'contact_name', 'address', 'emails', 'phone_numbers', 'active', 'associated'],
    },
    enableColumnResizing: true,
    enableRowSelection: false,
    // state: { rowSelection: selectedRowIds },
    // onRowSelectionChange: setSelectedRowIds,
    layoutMode: 'grid',
    muiTablePaperProps: { sx: { backgroundColor: 'white', padding: '18px', borderRadius: '18px' } },
    muiTableContainerProps: { sx: { maxHeight: '700px' } },
    muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'white',
        pt: 2,
        fontWeight: 'bold',
        height: 'auto',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        mb: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottomColor: theme.palette.secondary.main,
        pb: 2,
      },
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search Publications',
      sx: { minWidth: '500px' },
      variant: 'outlined',
    },
    renderTopToolbar: () => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '8px',
        }}
      >
        <Box sx={{ gridColumn: 'span 1' }}>
          <MrtGlobalFilterTextField table={table} />
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
					<Button
						sx={{ fontWeight: 'bold' }}
						variant='outlined'
						color={activeOnly ? 'success' : 'inherit'}
						startIcon={
							activeOnly ? <CheckBoxOutlined color='success' /> : <CheckBoxOutlineBlank color='action' />
						}
						onClick={() => setActiveOnly(!activeOnly)}
					>
						Active Only?
					</Button> */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '36px',
            gridColumn: 'span 2',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: 'solid 0px rgb(200,200,200)',
              borderRadius: '4px',
              padding: '8px 8px 8px 16px',
            }}
          >
            <span style={{ fontWeight: 'bold', color: theme.palette.primary.alt }}>FILTERS:</span>
            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={filters.active === 1 ? 'warning' : filters.active === 2 ? 'error' : 'disabled'}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  active: filters.active === 1 ? 2 : filters.active === 2 ? 3 : 1,
                }))
              }
            >
              {filters.active === 1 ? (
                <DirectionsRunOutlined />
              ) : filters.active === 2 ? (
                <NotAccessibleOutlined />
              ) : (
                <DirectionsRunOutlined />
              )}
            </IconButton>
            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={
                filters.address === 1 ? 'warning' : filters.address === 2 ? 'error' : 'disabled'
              }
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  address: filters.address === 1 ? 2 : filters.address === 2 ? 3 : 1,
                }))
              }
            >
              {filters.address === 1 ? (
                <RoomOutlined />
              ) : filters.address === 2 ? (
                <LocationOffOutlined />
              ) : (
                <NotListedLocationOutlined />
              )}
            </IconButton>
            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={filters.email === 1 ? 'warning' : filters.email === 2 ? 'error' : 'disabled'}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  email: filters.email === 1 ? 2 : filters.email === 2 ? 3 : 1,
                }))
              }
            >
              {filters.email === 1 ? (
                <MarkEmailReadOutlined />
              ) : filters.email === 2 ? (
                <UnsubscribeOutlined />
              ) : (
                <EmailOutlined />
              )}
            </IconButton>
            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={filters.phone === 1 ? 'warning' : filters.phone === 2 ? 'error' : 'disabled'}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  phone: filters.phone === 1 ? 2 : filters.phone === 2 ? 3 : 1,
                }))
              }
            >
              {filters.phone === 1 ? (
                <PhoneOutlined />
              ) : filters.phone === 2 ? (
                <PhoneDisabledOutlined />
              ) : (
                <PhoneOutlined />
              )}
            </IconButton>

            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={
                filters.hasAssociations === 1
                  ? 'warning'
                  : filters.hasAssociations === 2
                  ? 'error'
                  : 'disabled'
              }
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  hasAssociations:
                    filters.hasAssociations === 1 ? 2 : filters.hasAssociations === 2 ? 3 : 1,
                }))
              }
            >
              {filters.hasAssociations === 1 ? (
                <Diversity3 />
              ) : filters.hasAssociations === 2 ? (
                <PersonOffOutlined />
              ) : (
                <Diversity3 />
              )}
            </IconButton>

            <IconButton
              sx={{ fontWeight: 'bold' }}
              variant='outlined'
              color={
                filters.hasContracts === 1
                  ? 'warning'
                  : filters.hasContracts === 2
                  ? 'error'
                  : 'disabled'
              }
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  hasContracts: filters.hasContracts === 1 ? 2 : filters.hasContracts === 2 ? 3 : 1,
                }))
              }
            >
              {filters.hasContracts === 1 ? (
                <AttachMoneyOutlined />
              ) : filters.hasContracts === 2 ? (
                <MoneyOffCsredOutlined />
              ) : (
                <AttachMoneyOutlined />
              )}
            </IconButton>
          </Box>
          {/* <Button
						sx={{ fontWeight: 'bold' }}
						variant='outlined'
						color={filters.activeOnly ? 'success' : 'inherit'}
						startIcon={
							filters.activeOnly ? (
								<CheckBoxOutlined color='success' />
							) : (
								<CheckBoxOutlineBlank color='action' />
							)
						}
						onClick={() => setFilters((prev) => ({ ...prev, activeOnly: !filters.activeOnly }))}
					>
						Active Only?
					</Button>

					<Button
						sx={{ fontWeight: 'bold' }}
						variant='outlined'
						color={filters.noAddress ? 'success' : 'inherit'}
						startIcon={
							filters.noAddress ? (
								<CheckBoxOutlined color='success' />
							) : (
								<CheckBoxOutlineBlank color='action' />
							)
						}
						onClick={() => setFilters((prev) => ({ ...prev, noAddress: !filters.noAddress }))}
					>
						No Address?
					</Button> */}

          {/* <Button
						sx={{ fontWeight: 'bold' }}
						variant='outlined'
						color={filters.noEmail ? 'success' : 'inherit'}
						startIcon={
							filters.noEmail ? (
								<CheckBoxOutlined color='success' />
							) : (
								<CheckBoxOutlineBlank color='action' />
							)
						}
						onClick={() => setFilters((prev) => ({ ...prev, noEmail: !filters.noEmail }))}
					>
						No Email?
					</Button> */}

          {/* <Button
						sx={{ fontWeight: 'bold' }}
						variant='outlined'
						color={filters.noPhoneNumber ? 'success' : 'inherit'}
						startIcon={
							filters.noPhoneNumber ? (
								<CheckBoxOutlined color='success' />
							) : (
								<CheckBoxOutlineBlank color='action' />
							)
						}
						onClick={() => setFilters((prev) => ({ ...prev, noPhoneNumber: !filters.noPhoneNumber }))}
					>
						No Phone Number?
					</Button> */}
          <Box>
            <MrtToggleDensePaddingButton table={table} />
            <MrtToggleFiltersButton table={table} />
            <MrtShowHideColumnsButton table={table} />
            <MrtToggleFullScreenButton table={table} />
          </Box>
          <CreateNewContact />
          {/* <ContactMaterialTableDropDownMenu /> */}
        </Box>
      </Box>
    ),
  });

  useEffect(() => {
    try {
      let tempArray = [...allEntityContacts];

      // Filter for active contacts only
      if (filters.active === 1) {
        tempArray = tempArray.filter((e) => e.deleted === 0);
      } else if (filters.active === 2) {
        tempArray = tempArray.filter((e) => e.deleted === 1);
      }

      // Filter for contacts with no address
      if (filters.address === 1) {
        tempArray = tempArray.filter((e) => e.address_1 !== null);
      } else if (filters.address === 2) {
        tempArray = tempArray.filter((e) => e.address_1 === null);
      }

      // Filter for contacts with no email
      if (filters.email === 1) {
        const contactIdsWithEmail = new Set(allEmails.map((email) => email.entity_contact_id));
        tempArray = tempArray.filter((e) => contactIdsWithEmail.has(e.entity_contact_id));
      } else if (filters.email === 2) {
        const contactIdsWithEmail = new Set(allEmails.map((email) => email.entity_contact_id));
        tempArray = tempArray.filter((e) => !contactIdsWithEmail.has(e.entity_contact_id));
      }

      // Filter for contacts with no phone number
      if (filters.phone === 1) {
        const contactIdsWithPhoneNumber = new Set(
          allPhoneNumbers.map((phone) => phone.entity_contact_id)
        );
        tempArray = tempArray.filter((e) => contactIdsWithPhoneNumber.has(e.entity_contact_id));
      } else if (filters.phone === 2) {
        const contactIdsWithPhoneNumber = new Set(
          allPhoneNumbers.map((phone) => phone.entity_contact_id)
        );
        tempArray = tempArray.filter((e) => !contactIdsWithPhoneNumber.has(e.entity_contact_id));
      }

      // Filter for contacts with associations
      if (filters.hasAssociations === 1) {
        const contactIdsWithAssociations = new Set(
          allAssociationLink.map((asso) => asso.entity_contact_id)
        );
        tempArray = tempArray.filter((e) => contactIdsWithAssociations.has(e.entity_contact_id));
      } else if (filters.hasAssociations === 2) {
        const contactIdsWithAssociations = new Set(
          allAssociationLink.map((asso) => asso.entity_contact_id)
        );
        tempArray = tempArray.filter((e) => !contactIdsWithAssociations.has(e.entity_contact_id));
      }

      // Filter for contacts with contracts
      if (filters.hasContracts === 1) {
        const contactIdsWithContracts = new Set(
          allContracts.filter((e) => e.deleted !== 1).map((cont) => cont.entity_id)
        );
        const contactIdEntityAsso = allAssociationLink.filter((e) =>
          contactIdsWithContracts.has(e.entity_id)
        );
        const subContactIds = new Set(contactIdEntityAsso.map((e) => e.entity_contact_id));
        tempArray = tempArray.filter((e) => subContactIds.has(e.entity_contact_id));
        // console.log('apple: ', contactIdsWithContracts);
        // console.log('banana: ', contactIdEntityAsso);
        // console.log('grape: ', subContactIds);
        // console.log('peach: ', tempArray);
      } else if (filters.hasContracts === 2) {
        const contactIdsWithContracts = new Set(
          allContracts.filter((e) => e.deleted !== 1).map((cont) => cont.entity_id)
        );
        const contactIdEntityAsso = allAssociationLink.filter((e) =>
          contactIdsWithContracts.has(e.entity_id)
        );
        const subContactIds = new Set(contactIdEntityAsso.map((e) => e.entity_contact_id));
        tempArray = tempArray.filter((e) => !subContactIds.has(e.entity_contact_id));
      }

      setTableDataState(tempArray); // Correctly set filtered data
      setLoading(false);
    } catch (error) {
      console.log('Error during filtering:', error);
    }
  }, [allEntityContacts, filters, allEmails, allPhoneNumbers, allAssociationLink, allContracts]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box
      className='_manage_entity_contacts'
      sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default EntityContactsTable;
