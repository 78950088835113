import React, { useState } from 'react';
import axios from 'axios';

const PublicUploadTest = () => {
  const [files, setFiles] = useState([]);
  const [identifyingId, setIdentifyingId] = useState('');
  const [folderPath, setFolderPath] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [mediaAssetId, setMediaAssetId] = useState('');
  const [mediaTypeCode, setMediaTypeCode] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleFilesChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      alert('Please select at least one file.');
      return;
    }

    const formData = new FormData();

    // Append files
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    // Append other fields
    formData.append('identifyingId', identifyingId);
    formData.append('locationCode', folderPath);
    formData.append('displayName', displayName);
    if (mediaAssetId) formData.append('mediaAssetId', mediaAssetId);
    formData.append('mediaTypeCode', mediaTypeCode);

    try {
      const res = await axios.post('/upload/public', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResponse(res.data);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      setResponse(null);
    }
  };

  return (
    <div>
      <h1>Upload Files to Public Bucket</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Select Files:
            <input type='file' multiple onChange={handleFilesChange} />
          </label>
        </div>
        <div>
          <label>
            Identifying ID:
            <input
              type='text'
              value={identifyingId}
              onChange={(e) => setIdentifyingId(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Location Code:
            <input
              type='text'
              value={folderPath}
              onChange={(e) => setFolderPath(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Display Name:
            <input
              type='text'
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Media Asset ID (optional):
            <input
              type='text'
              value={mediaAssetId}
              onChange={(e) => setMediaAssetId(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Media Type Code:
            <input
              type='text'
              value={mediaTypeCode}
              onChange={(e) => setMediaTypeCode(e.target.value)}
              required
            />
          </label>
        </div>
        <button type='submit'>Upload</button>
      </form>

      {response && (
        <div>
          <h2>Upload Successful</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
          <img src={response.uploadedFiles[0].objectURL} alt='Uploaded File' />
        </div>
      )}

      {error && (
        <div>
          <h2>Error Occurred</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default PublicUploadTest;
