import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Document,
  Page,
  PDFViewer,
  Text,
  View,
  StyleSheet,
  Font,
  Link,
  Image,
} from '@react-pdf/renderer';

import { getContractCreditMemo } from '../../../../../_GlobalFunctions/contractFunctions';

const data = {
  ss_publication_contract_id: 10,
  ss_publication_id: 426,
  ss_publication_product_id: 3,
  ss_publication_contract_status_id: 5,
  ss_publication_payment_status_id: 1,
  ss_publication_custom_pricing_id: 1,
  entity_id: 767,
  user_id: 54155,
  promotion_set_id: null,
  last_status_change: '2024-07-26 00:00:00',
  time_created: '2024-07-26 00:00:00',
  contract_notes: null,
  contract_deleted: 0,
  version: 1,
  brands: [767, 1218, 1223],
  ss_publication_custom_pricing_type_id: 3,
  sold_price: '800.15',
  authorized_user_id: 54155,
  publication_pricing_notes: 'Standard pricing',
  description: 'STANDARD_PRICING',
  contract_due_date: '2024-03-29',
  days_until_due: -252,
  entity: 'Allen Company Inc ',
  brand_details: [
    {
      brand_id: 767,
      brand: 'Allen Company Inc ',
    },
    {
      brand_id: 1218,
      brand: 'TacSix',
    },
    {
      brand_id: 1223,
      brand: 'EZ Aim',
    },
  ],
  publication_details: {
    ss_publication_id: 426,
    ss_publication_code: 'TURKEY_MLR_2024',
    ss_publication_type_id: 2,
    description: 'All Turkey Product for the 2024 Turkey Season. ',
    detailed_description:
      'Equip your customers for a successful turkey hunting season with our comprehensive range of top-quality gear and accessories.',
    submit_contract_date: '2023-12-16',
    submit_art_date: '2024-01-29',
    production_start_date: '2024-01-01',
    start_date: '2024-02-29',
    expiration_date: '2024-03-29',
    flyer_url: null,
    internal_use_only: 0,
    inactive: 0,
    publication_type: 'Print Mailer',
  },
  payment_status: 'Unpaid',
  publicaiton_product: '1 spread print ss',
};

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 24,
    position: 'relative',
    flexDirection: 'column',
  },
  header: {
    gap: '16',
    backgroundColor: '#00325a',
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  headerText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
  line: {
    flex: 1,
    backgroundColor: 'white',
  },
  sportssouthAddress: {
    padding: 8,
    fontSize: 12,
    textAlign: 'center',
    color: '#666',
  },
  adderssInvoiceWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  address: {
    fontSize: 13,
    flex: 1,
    fontWeight: 'bold',
  },
  link: { color: '#00325a' },
  invoiceHeaders: {
    fontSize: 12,
    flex: 1,
  },
  invoiceHeaderRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    width: 300,
    marginBottom: 4,
  },
  invoiceHeaderCellLeft: {
    flex: 1,
    textAlign: 'right',
    color: '#444',
  },
  invoiceHeaderCellRight: {
    flex: 1,
    fontWeight: 'bold',
  },
  invoiceDetails: {
    marginTop: 40,
    flexDirection: 'row',
    gap: 24,
    flex: 1,
  },
  coverWrapper: {
    width: '120px',
    height: '160px',
    position: 'relative',
    backgroundColor: '#666666',
  },
  coverImage: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    marginTop: 50, // Adjust to leave space for the header
    fontSize: 12,
  },
  text: {
    fontSize: 12,
  },
  productItemization: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  contractPaymentWrapper: {
    fontSize: 12,
    margin: '16px 0',
  },
  contractPaymentMemo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contractPaymentAmount: {
    color: '#dd0000',
    fontWeight: 'bold',
  },
  footerBar: {
    backgroundColor: '#00325a',
    padding: 4,
    color: 'white',
  },
});

const PDFDocument = () => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [totalPaidIn, setTotalPaidIn] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [payments, setPayments] = useState([]);

  const fetch = async () => {
    let totalPaid = 0;
    try {
      const res = await getContractCreditMemo(9);

      res.map((e, idx) => (totalPaid = totalPaid + e.payment.payment_amount));

      setTotalPaidIn(totalPaid);
      setRemainingBalance(data.sold_price - totalPaid);
      setPayments([...res]);
      console.log('res: ', res);
      setLoading(false);
    } catch (err) {
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  loading && <div>... loading</div>;
  return (
    <PDFViewer style={{ height: '100vh', width: '100%' }}>
      <Document title='My Contract Invoice'>
        {/* {data.map((order, index) => ( */}
        <Page size='letter' style={styles.page}>
          {/* Header Bar */}
          <View style={styles.header}>
            <View style={[styles.line, { height: 2 }]} />
            <Text style={styles.headerText}>SPORTS SOUTH - MARKETING INVOICE</Text>
            <View style={[styles.line, { height: 2 }]} />
          </View>

          <View style={styles.sportssouthAddress}>
            <Text>
              {' '}
              101 Robert G. Harris, Shreveport, LA 71115 |{' '}
              <Link style={styles.link} src='mailto:marketing@sportssouth.biz'>
                marketing@sportssouth.biz
              </Link>{' '}
              | 1.800.388.3845
            </Text>
          </View>

          <View style={styles.adderssInvoiceWrapper}>
            <View style={styles.address}>
              <Text style={{ marginBottom: 4 }}>Dallas Miller</Text>
              <Text style={{ marginBottom: 4 }}>Dunken Lewis</Text>
              <Text style={{ marginBottom: 4 }}>888 Main Street</Text>
              <Text style={{ marginBottom: 4 }}>Birmingham, AL 89654</Text>
            </View>

            <View style={styles.invoiceHeaders}>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.invoiceHeaderCellLeft}>Publication:</Text>
                <Text style={styles.invoiceHeaderCellRight}>FLYER TOKEN WILL GO HERE</Text>
              </View>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.invoiceHeaderCellLeft}>Vendor:</Text>
                <Text style={styles.invoiceHeaderCellRight}>
                  #{data.entity_id} - {data.entity}
                </Text>
              </View>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.invoiceHeaderCellLeft}>Invoice Date:</Text>
                <Text style={styles.invoiceHeaderCellRight}>
                  {dayjs(data.publication_details.start_date).format('MMM DD, YYYY')}
                </Text>
              </View>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.invoiceHeaderCellLeft}>Due Date:</Text>
                <Text style={styles.invoiceHeaderCellRight}>
                  {dayjs(data.publication_details.expiration_date).format('MMM DD, YYYY')}
                </Text>
              </View>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.invoiceHeaderCellLeft}>Invoice #:</Text>
                <Text style={styles.invoiceHeaderCellRight}>9</Text>
              </View>
            </View>
          </View>

          <View style={styles.invoiceDetails}>
            <View style={styles.coverWrapper}>
              <Image
                style={styles.coverImage}
                src='https://content.osgnetworks.tv/gunsandammo/content/current-issue/ga_cover_300x407.jpg?dt=20241127'
              />
            </View>

            <View style={[{ flex: 1 }]}>
              <Text style={[{ marginBottom: 4 }]}>{data.publication_details.description}</Text>
              <Text
                style={[{ fontSize: 14, lineHeight: 1.1, color: '#00000099', marginBottom: 8 }]}
              >
                {data.publication_details.detailed_description}
              </Text>
              {/* <Text
                style={[{ fontSize: 14, lineHeight: 1.1, color: '#000000', fontWeight: 'bold' }]}
              >
                This publication is a mailer that has been sent to over 15,000 purchasing dealers
                across the full continental United States.
              </Text> */}
              <View style={[styles.productItemization]}>
                <Text style={{ fontSize: 14, borderBottom: '1px solid #000000', paddingBottom: 2 }}>
                  Single Page Print Provided By Sports South
                </Text>
                <Text style={{ fontSize: 16 }}>${data.sold_price}</Text>
              </View>
              <View style={[styles.contractPaymentWrapper]}>
                <Text style={{ fontWeight: 'bold', marginBottom: 4, fontSize: 16 }}>Payments:</Text>
                {}
                <View style={[styles.contractPaymentMemo]}>
                  <Text>Credit Memo - CR-1000 - Mar 08, 2024</Text>
                  <Text style={[styles.contractPaymentAmount]}>- $400.00</Text>
                </View>
                <View style={[styles.contractPaymentMemo]}>
                  <Text>&nbsp;</Text>
                  <Text
                    style={[
                      styles.contractPaymentAmount,
                      { margin: '8px 0', color: remainingBalance > 0 ? '#dd0000' : '#00cc00' },
                    ]}
                  >
                    Balance Due: ${parseFloat(remainingBalance, 2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {data.length > 9 && <View style={[styles.footerBar, { marginBottom: 4 }]}></View>}

          <View style={styles.footerBar}>
            <Text>
              footer text {totalPaidIn} | {remainingBalance}
            </Text>
          </View>
        </Page>
        {/* ))} */}
      </Document>
    </PDFViewer>
  );
};

export default PDFDocument;
