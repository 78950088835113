import { Hr } from '@react-email/components';
import React from 'react';

const Divider01 = () => {
  return (
    <Hr
      style={{
        marginTop: 16,
        borderColor: 'rgb(209,213,219)',
        marginBottom: 16,
      }}
    />
  );
};

export default Divider01;
