import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getAllContracts } from '../../Components/_GlobalFunctions/contractFunctions';
import { getAllPublication } from '../../Components/_GlobalFunctions/publicationFunctions';
import dayjs from 'dayjs';
// 1. Create the context
const ContractsContext = createContext();

// 2. Custom Hook to Access Context
export const useContractContext = () => {
  return useContext(ContractsContext);
};

// 3. Provider Component
export const ContractProvider = ({ children }) => {
  // State
  const [contracts, setContracts] = useState([]);
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [billingStatusId, setBillingStatusId] = useState(0);
  const [billingStatusItemsIds, setBillingStatusItemsIds] = useState([]);

  // Fetch Contracts and Publications (example API call)
  const fetchContractsPublications = useCallback(async () => {
    setLoading(true);

    try {
      const tempArray = [];
      const getContracts = await getAllContracts();
      const getPublications = await getAllPublication();

      getContracts.forEach((c) => {
        const matchingPublication = getPublications.find(
          (e) => e.ss_publication_id === c.ss_publication_id
        );

        if (matchingPublication) {
          tempArray.push({
            ...c,
            contract_due_date: matchingPublication.expiration_date,
            days_until_due: Math.ceil(
              dayjs(matchingPublication.expiration_date).diff(dayjs(), 'day')
            ),
          });
        }
      });

      setContracts(tempArray);
      setPublications(getPublications);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // // Add Product
  // const addProduct = useCallback((newProduct) => {
  //   setProducts((prev) => [...prev, newProduct]);
  // }, []);

  // // Remove Product
  // const removeProduct = useCallback((id) => {
  //   setProducts((prev) => prev.filter((product) => product.id !== id));
  // }, []);

  // Fetch products on component mount
  useEffect(() => {
    fetchContractsPublications();
  }, [fetchContractsPublications]);

  // Context Value
  const value = {
    contracts,
    publications,
    loading,
    fetchContractsPublications,
    billingStatusId,
    setBillingStatusId,
    billingStatusItemsIds,
    setBillingStatusItemsIds,
  };

  // Render
  return <ContractsContext.Provider value={value}>{children}</ContractsContext.Provider>;
};
