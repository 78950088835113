import axios from 'axios';
import dayjs from 'dayjs';

export const getSalesByMonth = async (mfg_id) => {
  const data = await axios.get(`/products/getSalesByMonth/'${mfg_id}`);
  return data.data;
};

export const topTypesByMfg = async (mfg_id) => {
  const data = await axios.get(`/products/topTypesByMfg/'${mfg_id}`);
  return data.data;
};

export const topCategoriesByMfg = async (mfg_id) => {
  const data = await axios.get(`/products/topCategoriesByMfg/'${mfg_id}`);
  return data.data;
};

export const mfgRanking = async (mfg_id) => {
  const data = await axios.get(`/products/mfgRanking/'${mfg_id}`);
  return data.data;
};

export const getTop10 = async (mfg_id) => {
  const data = await axios.get(`/products/getTop10/'${mfg_id}`);
  return data.data;
};

export const allManufacturers = async () => {
  const data = await axios.get(`/products/allManufacturers`);
  return data.data;
};

export const getPublicationView = async () => {
  const data = await axios.get(`/contracts/getPublicationView`);
  return data.data;
};

export const updateEntitiesRFnumber = async () => {
  const data = await axios.patch(``);
  return data.data;
};
