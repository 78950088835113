import React, { useEffect, useState } from 'react';
import {
  getDeposits,
  makePayment,
  getContractsRemainingBalance,
  setContractsPaidStatus,
} from '../../../../_GlobalFunctions/accountingFunctions';
import { Box, Button, InputAdornment, MenuItem, TextField, useTheme } from '@mui/material';
import { AttachMoney } from '@mui/icons-material';

const ContractAvailableFunds = ({
  user_id,
  primary_identifier,
  contract_amount,
  handleModalClose,
  entity_id,
}) => {
  const theme = useTheme();

  // LOCAL STATE
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [description, setDescription] = useState();
  const [note, setNote] = useState();
  const [contractAmount, setContractAmount] = useState(contract_amount);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [selectedDeposit, setSelectedDepost] = useState(0);
  const [dollarAmount, setDollarAmount] = useState('');
  const [depositAmount, setDepositAmount] = useState(0);
  const [successful, setSuccessful] = useState(0);

  // LOCAL STATE TRIGGERS
  const [triggerContractBalance, setTriggerContractBalance] = useState(0);

  // FUNCTIONS
  const fetch = async () => {
    try {
      setPageIsLoading(true);
      const res = await getDeposits();
      const entitysDeposits = res.data.filter((e) => e.entity_id === entity_id);
      setData(entitysDeposits);
      setPageIsLoading(false);
    } catch (err) {
      console.log('Error fetching deposits:', err);
    }
  };

  const validateCurrency = (input) => {
    const currencyRegex = /^\d*(\.\d{0,2})?$/;
    return currencyRegex.test(input);
  };

  const handleChange = (id, amount) => {
    if (!validateCurrency(amount)) return;
    console.log('amount: ' + amount + ' | ' + contractAmount);
    const newAmount = Number(amount) > Number(contractAmount) ? contractAmount : amount;
    setDepositAmount(newAmount);
    //const newAmount = contractAmount - amount;
    //const currentAmount = newAmount === 0 ? 0 : newAmount;

    //console.log('currentAmount: ' + currentAmount);

    //setRemainingAmount(Number(newerAmount));
  };

  const handleSelectChange = (e) => {
    setSelectedDepost(e.target.value);
  };

  const handleClick = async () => {
    try {
      const depositData = {
        user_id: user_id,
        primary_identifier: primary_identifier,
        note: note,
        description: description,
        deposit_id: selectedDeposit,
        amount: depositAmount,
      };

      const result = await makePayment(depositData); // Wait for each payment to complete

      if (result && result.affectedRows > 0) {
        setTriggerContractBalance(triggerContractBalance + 1);
        setDollarAmount('');

        const changePaidStatus = await setContractsPaidStatus(primary_identifier, 2);
        console.log('changePaidStatus: ', changePaidStatus.data);
        setSuccessful(1);
      } else {
        setSuccessful(2);
      }
    } catch (error) {
      console.error('Error processing payments:', error);
    }
  };

  useEffect(() => {
    fetch();
  }, [triggerContractBalance]);

  useEffect(() => {
    const callFunc = async () => {
      const amount = await getContractsRemainingBalance(
        primary_identifier,
        'MARKETING_CONTRACT',
        contract_amount
      );

      console.log('amount: ', amount);
      setContractAmount(parseFloat(amount).toFixed(2));
      setRemainingAmount(parseFloat(amount).toFixed(2));
    };
    callFunc();
    setDepositAmount(null);
  }, [triggerContractBalance]);

  if (pageIsLoading) return <div>...Is Loading</div>;

  return (
    <Box
      sx={{
        width: '480px',
        margin: '48px auto',
        border: `solid 2px ${theme.palette.primary.main}`,
        paddingBottom: '24px',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: `4px 4px 12px ${theme.palette.primary.main}33`,
      }}
    >
      {/* Table Header */}
      <Box
        sx={{
          marginBottom: '24px',
          backgroundColor: theme.palette.primary.main,
          padding: '16px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>Make Payment:</Box>
        <Box
          sx={{
            textAlign: 'right',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '.8em',
          }}
        >
          <Box sx={{ fontSize: '.8em' }}>Contract Balance:</Box>
          <Box>
            <span
              style={{
                display: 'inine-block',
                marginLeft: '4px',
                color: '#bce2ff',
                fontSize: '1.5em',
              }}
            >
              {contractAmount}
            </span>
          </Box>
        </Box>
      </Box>

      {/* TEXT FIELDS */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingX: '16px' }}>
        <TextField
          label='Description'
          defaultValue={null}
          onChange={(e) => setDescription(e.target.value)}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />

        <TextField
          label='Note'
          defaultValue={null}
          multiline
          rows={4}
          onChange={(e) => setNote(e.target.value)}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      </Box>

      {/* DEPOSIT DROPDOWN */}
      <Box sx={{ padding: '16px' }}>
        <TextField
          select
          label='Available Funds'
          value={selectedDeposit}
          onChange={(e) => handleSelectChange(e)}
          fullWidth
        >
          {[
            { entity_deposit_id: 0, deposit_amount: 0 },
            ...data.filter((ee) => ee.deposit_amount - ee.total_spent > 0),
          ].map((e, idx) => (
            <MenuItem key={idx} value={e.entity_deposit_id}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '16px',
                }}
              >
                <Box>
                  {e.entity_deposit_id === 0
                    ? '-- Choose Deposit'
                    : `#${e.entity_deposit_id} - ${e.entity_deposit_type} - ${e.description}`}
                </Box>
                <Box>
                  {e.entity_deposit_id === 0
                    ? ''
                    : `$${parseFloat(e.deposit_amount - e.total_spent).toFixed(2)}`}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* PAYMENT INPUT */}
      <Box>
        <Box
          sx={{ textAlign: 'center', paddingY: '16px', position: 'relative', marginBottom: '0' }}
        >
          <Box sx={{ display: 'inline-block', position: 'relative' }}>
            <TextField
              disabled={selectedDeposit === 0}
              type='text'
              label='Dollar Amount'
              value={depositAmount}
              onChange={(v) => {
                const record = data.find((ee) => ee.entity_deposit_id === selectedDeposit);
                const balance = parseFloat(record.deposit_amount) - parseFloat(record.total_spent);
                const ceiling =
                  contractAmount > parseFloat(record.deposit_amount)
                    ? parseFloat(record.deposit_amount)
                    : contractAmount;

                const newValue = isNaN(v.target.value)
                  ? 0
                  : Number(v.target.value) > Number(ceiling)
                  ? ceiling
                  : v.target.value;

                setDepositAmount(newValue);

                //handleChange(selectedDeposit, newValue);
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AttachMoney
                        sx={{
                          fontSize: '1.1em',
                          color: selectedDeposit === 0 ? '#c7c7c7' : theme.palette.accent.main,
                          fontWeight: 'bold',
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                '& input[type=number]': {
                  MozAppearance: 'textfield', // Firefox
                },
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                  {
                    WebkitAppearance: 'none', // Chrome, Safari, Edge
                    margin: 0,
                  },
              }}
            />
            <Box
              sx={{
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
                fontSize: '1.1em',
                marginBottom: '16px',
                position: 'absolute',
                top: '100%',
                right: 0,
                display: 'none',
                alignItems: 'center',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', display: 'inline-block', fontSize: '.9em' }}>
                Remaining Amount:
              </span>
              <span
                style={{
                  color: theme.palette.accent.main,
                  fontSize: '1em',
                  display: 'inline-block',
                  marginLeft: '4px',
                }}
              >
                ${parseFloat(remainingAmount).toFixed(2)} |{contractAmount - depositAmount}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Submit Button */}
      <Box sx={{ textAlign: 'right', paddingX: '16px', marginBottom: '16px' }}>
        <Button
          variant='contained'
          onClick={handleClick}
          sx={{ backgroundColor: theme.palette.save.main, fontWeight: 'bold' }}
        >
          Process Payment
        </Button>
      </Box>
      {/* MESSAGE */}
      {successful !== 0 && (
        <Box
          sx={{
            color: successful === 1 ? theme.palette.save.main : theme.palette.delete.main,
            fontWeight: 'bold',
            fontSize: '1em',
            textAlign: 'center',
          }}
        >
          {successful === 1
            ? 'Payment Made, Successfully!'
            : 'Something Went Wrong. Please Try Again.'}
        </Box>
      )}
    </Box>
  );
};

export default ContractAvailableFunds;
