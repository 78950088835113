import { Avatar, Box, Button, styled } from '@mui/material';
import React from 'react';

const links = [
  {
    id: 1,
    label: 'Markting Contracts',
    url: '/link',
  },
  {
    id: 2,
    label: 'Media Kit',
    url: '/link',
  },
  {
    id: 3,
    label: 'Brand Maintenance',
    url: '/link',
  },
  {
    id: 4,
    label: 'Product Audit',
    url: '/link',
  },
  {
    id: 5,
    label: 'Product Performance',
    url: '/link',
  },
];

const ManufacturerNavBar = () => {
  return (
    <Box
      sx={{
        height: '100px',
        paddingX: '24px',
        display: 'flex',

        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ flex: 1 }}></Box>
      <Box
        sx={{
          gap: '32px',
          display: { xs: 'none', md: 'flex' },
          '& .btn': {
            fontWeight: 'bold',
            fontSize: '1.1em',
          },
        }}
      >
        {links.map((link, idx) => (
          <Box key={idx}>
            <Button className='btn'>{link.label}</Button>
          </Box>
        ))}
      </Box>

      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Avatar
          alt='Remy Sharp'
          src='https://images.unsplash.com/photo-1688888745596-da40843a8d45?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        />
      </Box>
    </Box>
  );
};

export default ManufacturerNavBar;
