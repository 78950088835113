import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from '../Context/ManufacturerContext';

const ManufacturerBanner = () => {
  const { rank, selectedValue, loading, sum, pieFilter, monthFilter } = useManufacturerContext();

  const theme = useTheme();
  const differencePercent = Math.round((100 - rank) * 100) / 100;
  const formattedTotal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(sum);

  if (loading) {
    return (
      <Box
        sx={{
          padding: '40px',
          background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
        }}
      >
        <Skeleton variant='text' width='80%' height={40} />
        <Skeleton variant='text' width='60%' height={30} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton variant='rectangular' width='30%' height={40} />
          <Skeleton variant='rectangular' width='30%' height={40} />
          <Skeleton variant='rectangular' width='30%' height={40} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          padding: '30px',
          background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
          display: 'flex', // Use flexbox for the parent container
          justifyContent: 'space-between', // Distribute the items across the container
          alignItems: 'center', // Vertically align items in the center
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            marginLeft: '5%',
            width: '28%',
            background: `${theme.palette.primary.main}90`, // Light semi-transparent white
            borderRadius: '8px', // Rounded corners for a modern look
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)', // Light shadow for depth
            padding: '20px', // Padding for internal spacing
          }}
        >
          {selectedValue && (
            <Typography sx={{ fontSize: '20px' }}>
              {selectedValue} outperforms {differencePercent}% of manufacturers at Sports South
              ranking it in the top {rank}%
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.01)',
            width: '28%',
            borderRadius: '8px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
            padding: '20px',
          }}
        >
          {selectedValue && (
            <Typography sx={{ fontSize: '20px' }}>
              Sports South has distributed {formattedTotal}
              {pieFilter ? ` worth of ${pieFilter.label}` : ''} for {selectedValue} in{' '}
              {monthFilter ? `${monthFilter.axisValue}` : ''} 2024
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            marginRight: '5%',
            width: '28%',
            borderRadius: '8px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
            padding: '20px',
          }}
        >
          {selectedValue && (
            <Typography sx={{ fontSize: '20px' }}>Monthly Dealer Gain/Loss</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ManufacturerBanner;
