import React from 'react';
import { Button, Heading, Img, Section, Text } from '@react-email/components';
const PromotionBoard01 = ({ block }) => {
  return (
    <Section style={{ marginTop: 16, marginBottom: 32, padding: '0 16px' }}>
      <Img
        alt='Braun Collection'
        src={block.imageURL}
        style={{
          aspectRatio: '16/9',
          width: '100%',
          borderRadius: 12,
          objectFit: 'cover',
        }}
      />
      <Section style={{ marginTop: 32, textAlign: 'center' }}>
        <Text
          style={{
            marginTop: 16,
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 600,
            color: '#ddbb30',
          }}
        >
          {block.sub_header}
        </Text>
        <Heading
          as='h1'
          style={{
            fontSize: 32,
            lineHeight: '36px',
            fontWeight: 600,
            letterSpacing: 0.4,
            color: 'rgb(17,24,39)',
          }}
        >
          {block.header}
        </Heading>
        <Text
          style={{
            marginTop: 8,
            fontSize: 16,
            lineHeight: '24px',
            color: 'rgb(107,114,128)',
          }}
        >
          {block.text}
        </Text>
        {block.price !== 0 && (
          <Text
            style={{
              fontSize: 24,
              lineHeight: '24px',
              fontWeight: 600,
              color: 'rgb(17,24,39)',
            }}
          >
            {`$${block.price}`}
          </Text>
        )}

        <Button
          href={block.linkURL}
          style={{
            marginTop: 16,
            borderRadius: 8,
            backgroundColor: '#00325a',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            fontWeight: 600,
            color: 'rgb(255,255,255)',
          }}
        >
          {block.buttonText}
        </Button>
      </Section>
    </Section>
  );
};

export default PromotionBoard01;
