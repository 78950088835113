import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getSalesByMonth,
  getTop10,
  allManufacturers,
  topCategoriesByMfg,
  mfgRanking,
} from '../middleware/sandBoxMiddleware';

// 1. Create the context
const ManufacturerContext = createContext();

// 2. Provider Component
export const ManufacturerProvider = ({ children }) => {
  // State
  const [loading, setLoading] = useState(true);
  const [manufacturers, setManufacturers] = useState([]);
  const [axisX, setAxisX] = useState([]);
  const [axisY, setAxisY] = useState([]);
  const [axisX2, setAxisX2] = useState([]);
  const [axisY2, setAxisY2] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [seriesData, setSeriesData] = useState([]);
  const [pieSeries, setPieSeries] = useState([]);
  const [rank, setRank] = useState([]);
  const [publications, setPublications] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState([]);
  const [pieFilter, setPieFilter] = useState(null);
  const [sbmData, setSbmData] = useState(null);
  const [categorySeries, setCategorySeries] = useState([]);
  const [topItems, setTopItems] = useState([]);
  const [sum, setSum] = useState(0);
  const [salesByItem, setSalesByItem] = useState([]);
  const [monthFilter, setMonthFilter] = useState(null);

  // Fetch manufacturers on component mount
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const mfg = await allManufacturers();
        setManufacturers(mfg);

        const updatedData = sbmData.map((item) => ({
          ...item,
          sales: Math.round(parseFloat(item.sales)), // Ensure sales is a number
        }));

        const filteredData =
          pieFilter === null
            ? updatedData
            : updatedData.filter((item) => item.product_category_id === pieFilter.id);

        const tableData =
          monthFilter === null
            ? filteredData
            : filteredData.filter((item) => item.s_month === monthFilter.dataIndex + 1);

        const pieData =
          monthFilter === null
            ? updatedData
            : updatedData.filter((item) => item.s_month === monthFilter.dataIndex + 1);

        // Trending Sales (first table)
        const groupedByMonth = filteredData.reduce((acc, item) => {
          const month = item.s_month;
          if (!acc[month]) {
            acc[month] = { sales: 0 }; // Initialize as a number, not a string
          }
          acc[month].sales += item.sales;
          return acc;
        }, {});

        const months = Object.keys(groupedByMonth);
        const sales = Object.values(groupedByMonth).map((month) => month.sales);

        setAxisX(months);
        setAxisY(sales);

        // TopItems (second table)
        const topItemFilter = filteredData.map(({ as400_description, s_month, sales }) => ({
          id: as400_description,
          s_month,
          sales,
        }));

        const transformedTopItems = topItemFilter.reduce((acc, { id, s_month, sales }) => {
          const monthIndex = acc.findIndex((entry) => entry.s_month === s_month);

          if (monthIndex === -1) {
            acc.push({ s_month, [id]: sales });
          } else {
            acc[monthIndex][id] = sales;
          }

          return acc;
        }, []);
        setTopItems(transformedTopItems);

        // Sales By Category (third table)
        const gbc = updatedData.map(({ category_description, s_month, sales }) => ({
          id: category_description,
          s_month,
          sales,
        }));

        const transformedData = gbc.reduce((acc, { id, s_month, sales }) => {
          const entryIndex = acc.findIndex((entry) => entry.s_month === s_month);

          if (entryIndex === -1) {
            acc.push({ s_month, [id]: sales });
          } else {
            if (!acc[entryIndex][id]) {
              acc[entryIndex][id] = sales;
            } else {
              acc[entryIndex][id] += sales;
            }
          }
          return acc;
        }, []);
        setCategorySeries(transformedData);

        //Top Categories Pie Chart
        const pieMap2 = pieData.map(({ product_category_id, category_description, sales }) => ({
          id: product_category_id,
          value: Math.round(sales),
          category_description,
        }));

        const pieAcc = pieMap2.reduce((acc, item) => {
          if (!acc[item.id]) {
            acc[item.id] = { id: item.id, value: 0, label: item.category_description };
          }
          acc[item.id].value += item.value;
          return acc;
        }, {});

        const newPieSeries = Object.values(pieAcc);
        setPieSeries(newPieSeries);

        // Banner Total Sum
        const sumFilter = tableData.map(({ as400_description, sales, category_description }) => ({
          as400_description,
          sales,
          category: category_description,
        }));
        const totalSales = sumFilter.reduce((acc, { sales }) => acc + sales, 0);
        setSum(totalSales);

        //table items
        const sumItems = sumFilter.reduce((acc, { as400_description, sales, category }) => {
          if (!acc[as400_description]) {
            acc[as400_description] = { as400_description, sales: 0, category };
          }
          acc[as400_description].sales += sales;
          return acc;
        }, {});
        const sumItems2 = Object.values(sumItems);
        setSalesByItem(sumItems2);
      } catch (error) {
        console.error('Failed to fetch manufacturers:', error);
      }
    };

    fetchManufacturers();
    setLoading(false);
  }, [pieFilter, sbmData, monthFilter]);

  const handleChange = async (e) => {
    setLoading(true);
    setPieFilter(null);
    const sv = e.target.value;
    setSelectedValue(e.target.value);
    const match = manufacturers.find((m) => m.description === sv);
    const mfgId = match ? match.product_manufacturer_id : null;
    const sData = await getSalesByMonth(mfgId);
    setSbmData(sData);
    const mfg123 = await mfgRanking(mfgId);
    const rankMap = mfg123.map((e) => e.percentile);

    setRank(rankMap);
    setLoading(false);
  };

  // Context value
  const value = {
    manufacturers,
    categorySeries,
    pieFilter,
    setPieFilter,
    axisX,
    axisY,
    selectedValue,
    handleChange,
    seriesData,
    pieSeries,
    rank,
    loading,
    topItems,
    sum,
    salesByItem,
    monthFilter,
    setMonthFilter,
  };

  // Render
  return <ManufacturerContext.Provider value={value}>{children}</ManufacturerContext.Provider>;
};

export const useManufacturerContext = () => {
  return useContext(ManufacturerContext);
};
