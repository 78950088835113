import React from 'react';
import { Box } from '@mui/material';
import DashBoardV2 from '../Contracts/components/DashBoard/DashBoardV2';
import ContractsDashboardWrapper from '../Contracts/components/DashBoard/ContractsDashboardWrapper';
//import ContractForm from '../Contracts/components/ManageContracts/components/ContractForm';
//import ContractFormMobile from '../Contracts/components/ManageContracts/components/ContractFormMobile';

const Manufacturers = () => {
  return (
    <>
      <ContractsDashboardWrapper />
    </>
  );
};

export default Manufacturers;
