import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Marketing from '../Components/Marketing/Marketing';
import MarketingLayout from '../Components/Marketing/component/MarketingLayout/MarketingLayout';
import Publications from '../Components/Publications/Publications';
import EntityContext from '../Context/Entity/EntityContext';
import ManageContacts from '../Components/Entities/components/ManageContacts/ManageContacts';
import ManageEntities from '../Components/Entities/components/ManageEntities/ManageEntities';
import DashBoard from '../Components/Contracts/components/DashBoard/DashBoard';
import PublicationContext from '../Context/Marketing/PublicationContextV2';
import MarketingContext from '../Context/Marketing/MarketingContext';
import ContractsByBrandId from '../Components/Contracts/components/ManageContracts/components/ContractsByBrandId';
import ContractCards from '../Components/Contracts/components/ManageContracts/components/ContractCards/ContractCards';
import ContactContext from '../Context/Entity/EntityContacts/EntityContactContext';
import EntityContactContext from '../Context/Marketing/EntityContactContext';
import EntContext from '../Context/Marketing/EntityContext';
import EntityContacts from '../Components/EntityContacts/EntityContacts';

import ContractContext from '../Context/Marketing/ContractContext';
import EntitiesV2Context from '../Context/Marketing/EntitiesV2Context';
import PublicationsV2 from '../Components/Publications/PublicationsV2';
import OptionUpdate from '../Components/Marketing/component/OptionUpdate/OptionUpdate';
import DashBoardV2 from '../Components/Contracts/components/DashBoard/DashBoardV2';
import ContractsDashboardWrapper from '../Components/Contracts/components/DashBoard/ContractsDashboardWrapper';
import ContractView from '../Components/Contracts/components/ContractView/ContractView';
import PublicationDetails from '../Components/Publications/components/PublicationDetails/PublicationDetails';

const MarketingRoutes = () => {
  const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <>
      <MarketingContext>
        <EntityContext>
          <EntContext>
            <ContactContext>
              <EntityContactContext>
                <PublicationContext>
                  <ContractContext>
                    <MarketingLayout>
                      <Routes>
                        {/* ENTITY ROUTES */}
                        {userPermission[966] && (
                          <Route
                            path='/entity/manage-entities/all-entities'
                            element={<ManageEntities />}
                          />
                        )}

                        {/* CONTACT ROUTES */}
                        {userPermission[965] && (
                          <Route
                            path='/contact/manage-contacts/all-contacts'
                            element={<ManageContacts />}
                          />
                        )}
                        {userPermission[965] && (
                          <Route
                            path='/entity-contacts/manage-entity-contacts'
                            element={
                              <EntitiesV2Context>
                                <EntityContacts />
                              </EntitiesV2Context>
                            }
                          />
                        )}

                        {/* CONTRACT ROUTES */}
                        <Route path='/contract/dashboard/:id/:title' element={<DashBoard />} />
                        <Route path='/contract/view/:id' element={<ContractView />} />
                        <Route path='/contract/dashboard' element={<DashBoardV2 />} />
                        <Route
                          path='/contracts/entity-contracts/:entityId/:subBrandId'
                          element={<ContractsByBrandId />}
                        />

                        {/* PUBLICATION ROUTES */}
                        <Route
                          path='/publications/contracts/dashboard/:id/:title'
                          element={<DashBoard />}
                        />
                        <Route path='/publications/view/:pub_id' element={<PublicationDetails />} />
                        <Route path='/publications/v2' element={<PublicationsV2 />} />
                        <Route path='/publications/*' element={<Publications />} />

                        {/* <Route path='/update/option-values' element={<OptionUpdate />} /> */}

                        <Route path='/*' element={<ContractsDashboardWrapper />} />
                      </Routes>
                    </MarketingLayout>
                  </ContractContext>
                </PublicationContext>
              </EntityContactContext>
            </ContactContext>
          </EntContext>
        </EntityContext>
      </MarketingContext>
    </>
  );
};

export default MarketingRoutes;
