import React from 'react';
import CreditForm from './CreditForm';
import { Box } from '@mui/material';

const CreditFormExport = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CreditForm />
    </Box>
  );
};

export default CreditFormExport;
