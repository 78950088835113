import React from 'react';
import { Box } from '@mui/material';
import ManufacturerPie from './ManufacturerPie';
import ManufacturerBanner from './ManufacturerBanner';
import ManufacturerSelect from './ManufacturerSelect';
import ManufacturerLineCharts from './ManufacturerLineCharts';
import ManufacturerTable from './ManufacturerTable';
import ManufacturerNavBar from '../../../../../Manufacturers/components/ManufacturerNavBar';

const ManufacturerDashboard = () => {
  return (
    <Box>
      <ManufacturerSelect />
      <ManufacturerNavBar />
      <ManufacturerBanner />
      <Box
        className='full-chart-wrapper'
        sx={{
          display: 'flex',
          flexDirection: 'rows',
          gap: '32px',
          width: '100%',
          maxWidth: '1650px',
          marginX: 'auto',
          flexWrap: 'wrap',
        }}
      >
        <Box className='left' sx={{ flex: 1, minWidth: '700px' }}>
          <ManufacturerLineCharts />
        </Box>
        <Box className='right' sx={{ width: '100%', maxWidth: '700px' }}>
          <ManufacturerPie />
          <ManufacturerTable />
        </Box>
      </Box>
    </Box>
  );
};

export default ManufacturerDashboard;
