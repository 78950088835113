import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAllPublication,
  getAllPublicationProducts,
  getAllPublicationTypes,
} from '../../../_GlobalFunctions/publicationFunctions';
import { Box, Chip, Link, styled, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

const colors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A6',
  '#A633FF',
  '#33FFF5',
  '#FF8C33',
  '#33FF8C',
  '#8C33FF',
  '#FF338C',
  '#33A6FF',
  '#A6FF33',
  '#FF5733',
  '#57FF33',
  '#33FFA6',
  '#FFA633',
  '#33A6FF',
  '#8C33FF',
  '#FF338C',
  '#A633FF',
  '#338CFF',
  '#FF8C33',
  '#33FF8C',
  '#8CFF33',
  '#FF33A6',
  '#A6FF33',
  '#33FFA6',
  '#33A6FF',
  '#FF5733',
  '#57FF33',
];

// Material Styled Components
const TopBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url('/images/publications/turkey_hunting_large.jpg')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '8px',
  aspectRatio: '21/9',
}));

const PublicationDetails = () => {
  // INIT's
  const { pub_id } = useParams();
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const work = async () => {
      try {
        const [pubs, types, products] = await Promise.all([
          getAllPublication(),
          getAllPublicationTypes(),
          getAllPublicationProducts(),
        ]);

        const pubDetails = pubs.find((e) => e.ss_publication_id === parseInt(pub_id));
        const allDetails = {
          ...pubDetails,
          publication_type: types.find(
            (t) => t.ss_publication_type_id === pubDetails.ss_publication_type_id
          ).description,
          products: [
            ...products
              .filter(
                (e) =>
                  typeof e.types === 'string' &&
                  e.types.split(',').includes(String(pubDetails.ss_publication_type_id))
              )
              .map((p) => ({
                ss_publication_product_id: p.ss_publication_product_id,
                description: p.description,
              })),
          ],
        };
        setData({ ...allDetails });
        console.log('products: ', products);
        setLoading(false);
      } catch (error) {
        return console.log('error: ', error);
      }
    };
    work();
  }, [pub_id]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <div>...Loading</div>;
  }

  return (
    <>
      <Box
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          width: '100%',
          maxWidth: '1600px',
          paddingX: '24px',
          marginX: 'auto',
          paddingY: '24px',
          display: 'grid',
          boxSizing: 'border-box',
          gap: '24px',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: 'repeat(12, 1fr)' },
          gridTemplateAreas: {
            xs: `
            'details'
            'dates'
            'flip'
            'cat'
            'order'
            `,
            sm: `
             'details details'
            'dates dates'
            'flip flip'
            'cat order'           
            `,
            lg: `
            'details details details details details details cat cat cat  order order order'
            'dates dates dates dates dates dates cat cat cat  order order order'
            'flip flip flip flip flip flip cat cat cat order order order'
           
           `,
          },
        }}
      >
        <Box
          sx={{
            gridArea: 'details',
            flexDirection: 'column',
          }}
        >
          <TopBannerWrapper sx={{ marginBottom: '16px' }}></TopBannerWrapper>
          <Typography variant='h5'>{data.description}</Typography>
          <Typography>{data.detailed_description}</Typography>
        </Box>

        <Box
          sx={{
            gridArea: 'dates',
            display: 'flex',
            gap: '16px',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginTop: '16px',
          }}
        >
          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Submit Contract
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.submit_contract_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Art Due
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.submit_art_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              In Production
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.production_start_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Start
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.start_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Expires
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.expiration_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>
        </Box>
        {data.flyer_url && (
          <Box
            sx={{
              gridArea: 'flip',
              marginY: { xs: '16px', md: '16px 0' },
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              padding: '16px',
              borderRadius: '8px',
            }}
          >
            <img
              alt='cover'
              src='/images/publications/turkey_hunting_portrait.jpg'
              style={{
                width: '100px',
                border: 'solid 1px white',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            />
            <Link
              href={data.flyer_url}
              target='_blank'
              sx={{
                fontWeight: 'bold',
                flex: 1,
                color: 'white',
              }}
            >
              View Digital Flipbook...
            </Link>
          </Box>
        )}

        <Box
          sx={{
            gridArea: 'cat',
            width: '100%',
            maxWidth: '350px',
            marginX: 'auto',
            paddingX: '16px',
          }}
        >
          <h3>Product Categories:</h3>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {[...Array(20)].map((_, idx) => (
              <Chip
                sx={{
                  backgroundColor: `${theme.palette.accent.main}22`,
                  color: theme.palette.accent.main, //colors[idx],
                  fontWeight: 'bold',
                  borderColor: theme.palette.accent.main, //colors[idx],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                }}
                key={idx}
                label='Chip Filled'
              />
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            gridArea: 'order',
            width: '100%',

            backgroundColor: '#e7e7e7',
            marginX: 'auto',
          }}
        >
          <h5>Create Contract</h5>
        </Box>
      </Box>
    </>
  );
};

export default PublicationDetails;
