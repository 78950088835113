import axios from 'axios';

export const insertOrUpdatePublication = async (data) => {
  console.log('passed dataa: ', data, ' | ', data.start_date);
  try {
    const endpoint =
      data.ss_publication_id < 1
        ? '/publications/insertPublication'
        : '/publications/updatePublication';
    const response = await axios.post(endpoint, { data });
    return response;
  } catch (error) {
    console.log('error insertOrUpdateContact: ', error);
  }
};
