import React, { useEffect, useState } from 'react';
import BillingStatus from '../../../Manufacturers/components/BillingStatus';
import AllInvoices from '../../../Manufacturers/components/AllInvoices/AllInvoices';

import MostOutstanding from '../../../Manufacturers/components/MostOutstanding';
import { useContractContext } from '../../../../Context/Contracts/ContractsDashboardContext';
import ContractCreditMemo from '../ContractCreditMemo/ContractCreditMemo';
import { getAllContractsData } from '../../../_GlobalFunctions/contractFunctions';
import ContractPayment from '../ContractPayment/ContractPayment';
import ContractAvailableFunds from '../ContractPayment/components/ContractAvailableFunds';

const DashBoardV2 = () => {
  // CONTEXT STATE
  const { publications, loading } = useContractContext();

  // LOCAL STATE
  const [pageLoading, setPageLoading] = useState(true);
  const [contracts, setContracts] = useState([]);

  // FUNCTIONS
  const fetchData = async () => {
    try {
      const res = await getAllContractsData();
      setContracts(res.filter((e) => e.contract_deleted !== 1));
      console.log('res', res);
      setPageLoading(false);
    } catch (err) {
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading && pageLoading) {
    return <div>...Loading</div>;
  }

  return (
    <>
      <BillingStatus contracts={contracts} />
      {/* <ContractAvailableFunds user_id={54155} primary_identifier={10} /> */}
      {/* <MostOutstanding publications={publications} contracts={contracts} /> */}
      {/* <ContractCreditMemo /> */}
      {/* <ContractPayment /> */}
      <AllInvoices publications={publications} contracts={contracts} />
    </>
  );
};

export default DashBoardV2;
