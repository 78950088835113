import React from 'react';
import { Column, Img, Link, Row, Section } from '@react-email/components';
const Header1000 = () => {
  return (
    <Section
      style={{
        padding: 16,
        backgroundColor: '#002c59',
        color: 'white',
      }}
    >
      <Row>
        <Column style={{ width: '100%', textAlign: 'center' }}>
          <Img
            alt='React Email logo'
            src='https://hub.sportssouth.app/static/media/ssDuckSquare.22f6eb3360bf79d90f78.png'
            style={{ margin: '0 auto', display: 'inline-block', width: '240px', height: 'auto' }}
          />
        </Column>
      </Row>
    </Section>
  );
};

export default Header1000;
