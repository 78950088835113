import { Route, Routes } from 'react-router-dom';
import HornadyPim from '../Components/Marketing/component/SandBox/HornadyPim/HornadyPim';
import FunctionTest from '../Components/Marketing/component/SandBox/Cameron/FunctionTest';
import FileUploadButton from '../Components/Marketing/component/SandBox/Tony/FileUploader/components/FileUploadButton';
import FileUploader from '../Components/Marketing/component/SandBox/Tony/FileUploader/FileUploader';
import PublicUploadTest from '../Components/Marketing/component/SandBox/Tony/FileUploader/PublicUploadTest';
import BuildPdf from '../Components/Marketing/component/SandBox/Tony/BuildPdf/BuildPdf';

const SandBoxRoutes = () => {
  return (
    <Routes>
      <Route path='/hornady-pim' element={<HornadyPim />} />
      <Route path='/cameron/function-test' element={<FunctionTest />} />
      {/* <Route path='/tony/file-uploader' element={<FileUploader />} /> */}
      <Route path='/tony/public-upload-test' element={<PublicUploadTest />} />
      <Route path='/tony/build-pdf' element={<BuildPdf />} />

      <Route path='/*' element={<h4>SandBox Stuff</h4>} />
    </Routes>
  );
};

export default SandBoxRoutes;
