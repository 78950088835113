import { Column, Container, Img, Link, Row, Section, Text } from '@react-email/components';
import React from 'react';

const Footer1000 = () => {
  return (
    <>
      <Section style={{ backgroundColor: '#002c59', padding: '32px 16px' }}>
        <Row
          style={{
            width: '100%',
            maxWidth: '584px',
            margin: '0px auto',
            textAlign: 'center',
          }}
        >
          <Column
            style={{
              width: '100%',
              maxWidth: '220px',
              display: 'inline-block',
              color: 'white',
              textAlign: 'center',
              margin: '0 auto 16px auto',
            }}
          >
            <Img
              alt='Braun Analogue Clock'
              height={180}
              src='https://mcusercontent.com/0d02b61966a2eac02ea807021/images/52856428-bc7d-3914-9ca8-cf14bcb7b720.jpg'
              style={{
                width: '210px',
                borderRadius: 8,
                objectFit: 'cover',
                border: 'solid 2px white',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </Column>
          <Column
            style={{
              width: '100%',
              maxWidth: '340px',
              display: 'inline-block',
              color: 'white',
              padding: '0 8px',
            }}
          >
            <Text style={{ padding: 0, margin: 0, marginBottom: '4px', fontSize: '28px' }}>
              Chris Smith
            </Text>
            <Text
              style={{
                padding: 0,
                margin: 0,
                marginBottom: '16px',
                fontSize: '20px',
                color: 'rgb(200,200,200)',
              }}
            >
              Account Manager
            </Text>
            <Text style={{ padding: 0, margin: 0, fontSize: '20px' }}>833.408.3227</Text>
            <Link href='#' sx={{}}>
              <Text
                style={{
                  padding: 0,
                  margin: 0,
                  marginBottom: '16px',
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                Chris.Smith@SportsSouth.Biz
              </Text>
            </Link>
            <Link href='#'>
              <Text style={{ padding: 0, margin: 0, fontSize: '20px' }}>
                www.TheShootingWarehouse.com
              </Text>
            </Link>
          </Column>
        </Row>

        <Row
          style={{
            width: '100%',
            maxWidth: '584px',
            margin: '0px auto',
          }}
        >
          <Column
            style={{
              width: '100%',
              display: 'inline-block',
            }}
          >
            <Text style={{ textAlign: 'center', color: 'white' }}>
              eeLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation
            </Text>
          </Column>
        </Row>
        <Row
          style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0px auto',
          }}
        >
          <Column
            style={{
              width: '100%',
            }}
          >
            <Text style={{ textAlign: 'center', color: 'white' }}>
              Our mailing address is:
              <br />
              101 Robert G. Harris Dr.
              <br />
              Shreveport, LA 71115
            </Text>
          </Column>
        </Row>
      </Section>
    </>
  );
};

export default Footer1000;
