import { Box, Button, Divider, styled, useTheme } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LeftSide from './components/LeftSide';
import Center from './components/Center';
import { getAllContractsData } from '../../../_GlobalFunctions/contractFunctions';

const ContractView = () => {
  // INIT,s
  const { id } = useParams();
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const getData = await getAllContractsData();
      const filteredContract = getData.find((e) => e.ss_publication_contract_id === parseInt(id));
      setContract(filteredContract);
      setLoading(false);
    };
    fetch();
  }, [id]);

  if (loading) {
    return <div>...loading</div>;
  }

  console.log('contract: ', contract);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        maxWidth: '1600px',
        marginX: 'auto',
        paddingX: '24px',
        gap: '32px',
      }}
    >
      {/* LEFT COLUMN */}
      <LeftSide contractId={id} contract={contract} />

      {/* CENTER COLUMN */}
      <Center contract={contract} />

      {/* RIGHT COLUMN */}
      <Box sx={{ width: '100%', maxWidth: '375px' }}>right</Box>
    </Box>
  );
};

export default ContractView;
