import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  lighten,
  Skeleton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Add,
  AttachMoney,
  Cancel,
  CheckCircle,
  ContentCopy,
  Email,
  Event,
  Key,
  Notes,
  Phone,
  Refresh,
  Today,
  Numbers,
} from '@mui/icons-material';
import ExcelExport from '../_GlobalComponents/ExcelExport';
import dayjs from 'dayjs';
import CreditModal from './CreditModal';
import { setIsLoading } from '../../Redux/LoadingSlice';
import InnerTable from './InnerTable';
import { trimIfString } from '../_GlobalFunctions/InfoValidation';
import MobileList from '../_GlobalComponents/MobileList';

const Attendees = () => {
  const userId = useSelector((state) => state.userInformation.value.userID);
  const [attendees, setAttendees] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [hotels, setHotels] = useState([]);
  const [hotelDays, setHotelDays] = useState([]);
  const [badges, setBadges] = useState(null);
  const [stats, setStats] = useState(null);
  const [hoveredInvitationCode, setHoveredInvitationCode] = useState(null);
  const [hoveredPhone, setHoveredPhone] = useState(null);
  const [hoveredEmail, setHoveredEmail] = useState(null);
  const [hoveredHotelTotal, setHoveredHotelTotal] = useState(null);
  const [hoveredCreditAmount, setHoveredCreditAmount] = useState(null);
  const [hoveredCreditMemo, setHoveredCreditMemo] = useState(null);
  const [hoveredHotelCheckIn, setHoveredHotelCheckIn] = useState(null);
  const [hoveredHotelCheckOut, setHoveredHotelCheckOut] = useState(null);
  const [hoveredHotelNotes, setHoveredHotelNotes] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const getAttendees = useCallback(() => {
    dispatch(setIsLoading(true));
    axios
      .post('/attendees/all', {
        userId: userId,
      })
      .then((response) => {
        setAttendees(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, [userId, dispatch]);

  const getHotels = () => {
    axios
      .get('/attendees/hotels')
      .then((response) => {
        setHotels(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getHotelDays = () => {
    axios
      .get('/attendees/hotels/days')
      .then((response) => {
        setHotelDays(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBadges = useCallback((visibleAttendees = []) => {
    if (visibleAttendees.length === 0) {
      return;
    }
    Promise.allSettled(
      visibleAttendees.map((attendeeId) =>
        axios.get('/attendees/badges', {
          params: {
            attendeeId: attendeeId,
          },
        })
      )
    )
      .then((responses) => {
        const newBadges = {};
        responses.forEach((response, index) => {
          if (response.status === 'fulfilled') {
            newBadges[visibleAttendees[index]] = response.value.data;
          } else {
            newBadges[visibleAttendees[index]] = [];
          }
        });
        setBadges(newBadges);
      })
      .catch((err) => {
        console.error(err);
        setBadges({});
      });
  }, []);

  const getStats = () => {
    setStats(null);
    axios
      .post('/attendees/stats')
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.error(error);
        setStats([]);
      });
  };

  const recordToDatabase = ({
    hotelCost,
    hotelName,
    hotelCheckIn,
    hotelCheckOut,
    hotelNotes,
    attendeeId,
  }) => {
    axios
      .post('/attendees/credit/record', {
        hotelCost: hotelCost,
        hotelName: hotelName,
        hotelCheckIn: hotelCheckIn,
        hotelCheckOut: hotelCheckOut,
        hotelNotes: hotelNotes,
        attendeeId: attendeeId,
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitCredit = (creditDetails) => {
    const trimmedName = trimIfString(
      creditDetails.hotel === 'Other' ? creditDetails.otherHotel : creditDetails.hotel
    );
    const trimmedNotes = trimIfString(creditDetails.notes);

    return new Promise((resolve, reject) => {
      axios
        .post('/attendees/credit/apply', {
          accountNumber: selectedAttendee.account_number,
          creditAmount: creditDetails.creditAmount,
        })
        .then((response) => {
          recordToDatabase({
            hotelCost: creditDetails.creditAmount,
            hotelName: trimmedName,
            hotelCheckIn: creditDetails.checkInDay,
            hotelCheckOut: creditDetails.checkOutDay,
            hotelNotes: trimmedNotes,
            attendeeId: selectedAttendee.a_id,
          });
          resolve(response.data);
          getAttendees();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    getAttendees();
  }, [getAttendees]);

  useEffect(() => {
    getHotels();
    getHotelDays();
    getStats();
  }, []);

  useEffect(() => {
    if (selectedAttendee) {
      setIsModalOpen(true);
    }
  }, [selectedAttendee]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAttendee(null);
  };

  const handleSetPagination = (newValue) => {
    setBadges(null);
    setPagination(newValue);
  };

  const handleRefresh = () => {
    getAttendees();
    getHotels();
    getHotelDays();
    const visibleAttendees = table
      .getRowModel()
      .flatRows.filter((row) => row.original.a_id != null)
      .map((row) => row.original.a_id);
    getBadges(visibleAttendees);
    getStats();
  };

  const handleCopy = (text) => {
    setSnackbarOpen(false);
    navigator.clipboard.writeText(text);
    setTimeout(() => setSnackbarOpen(true), 250);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      header: 'Account #',
      accessorKey: 'account_number',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Account',
      accessorKey: 'company_name',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Registered',
      accessorKey: 'registered',
      Cell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {row.original.registered === 'true' ? (
            <CheckCircle sx={{ color: 'white', backgroundColor: 'green', borderRadius: '50%' }} />
          ) : (
            <Cancel sx={{ color: 'white', backgroundColor: 'red', borderRadius: '50%' }} />
          )}
        </Box>
      ),
    },
    {
      header: 'Hotel Booked',
      accessorKey: 'hotel_booked',
      Cell: ({ row }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.original.hotel_booked === 'true' ? (
              <CheckCircle sx={{ color: 'white', backgroundColor: 'green', borderRadius: '50%' }} />
            ) : (
              <Cancel sx={{ color: 'white', backgroundColor: 'red', borderRadius: '50%' }} />
            )}
          </Box>
        );
      },
    },
    {
      header: 'Travel Survey',
      accessorKey: 'travel_survey',
      Cell: ({ row }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.original.travel_survey === 'true' ? (
              <CheckCircle sx={{ color: 'white', backgroundColor: 'green', borderRadius: '50%' }} />
            ) : (
              <Cancel sx={{ color: 'white', backgroundColor: 'red', borderRadius: '50%' }} />
            )}
          </Box>
        );
      },
    },
    {
      header: 'Credit Amount',
      id: 'fn_credit_amount',
      accessorFn: (row) =>
        row.credit_amount
          ? Number(row.credit_amount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          : '',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Hotel',
      accessorKey: 'hotel_name',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Sales Rep',
      accessorKey: 'sales_rep',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Date Invited',
      id: 'fn_date_invited',
      accessorFn: (row) => dayjs(row.time_created).format('MM-DD-YYYY'),
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns: columns,
    data: attendees,
    enableHiding: false,
    enableRowActions: attendees.some((attendee) => attendee.allow_credit !== 'false'),
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    positionToolbarAlertBanner: 'bottom',
    renderToolbarInternalActions: ({ table }) => {
      return (
        <Box sx={{ whiteSpace: 'pre' }}>
          <MrtToggleGlobalFilterButton table={table} />
          <MrtToggleFiltersButton table={table} />
          <MrtToggleDensePaddingButton table={table} />
          <MrtToggleFullScreenButton table={table} />
          <IconButton tabIndex={0} type='button' aria-label='Refresh' onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        </Box>
      );
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          '& > .MuiPaper-root': {
            boxShadow: 'none',
            borderStyle: 'solid',
            borderColor: lighten(theme.palette.primary.main, 0.5),
            pt: 1,
            '& > .MuiBox-root:first-of-type': {
              '& > .MuiBox-root': {
                '& > .MuiBox-root': {
                  '& > .MuiBox-root': {
                    '& > .MuiTypography-root': {
                      color: lighten(theme.palette.primary.main, 0.25),
                    },
                  },
                },
              },
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredInvitationCode(row.original.id)}
            onMouseLeave={() => setHoveredInvitationCode(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Invite Code:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Key sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.invitation_code ? row.original.invitation_code : 'N/A'}
              {hoveredInvitationCode === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.invitation_code || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredPhone(row.original.id)}
            onMouseLeave={() => setHoveredPhone(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Phone:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Phone sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.phone_number ? (
                <a
                  href={`tel:${row.original.phone_number}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  {row.original.phone_number}
                </a>
              ) : (
                'N/A'
              )}
              {hoveredPhone === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.phone_number || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredEmail(row.original.id)}
            onMouseLeave={() => setHoveredEmail(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Email:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Email sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.email_address ? (
                <a
                  href={`mailto:${row.original.email_address}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  {row.original.email_address}
                </a>
              ) : (
                'N/A'
              )}
              {hoveredEmail === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.email_address || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredCreditAmount(row.original.id)}
            onMouseLeave={() => setHoveredCreditAmount(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Credit Amount:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <AttachMoney sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.credit_amount ? row.original.credit_amount : 'N/A'}
              {hoveredCreditAmount === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.credit_amount || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredCreditMemo(row.original.id)}
            onMouseLeave={() => setHoveredCreditMemo(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Credit Memo:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Numbers sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.credit_memo_number ? row.original.credit_memo_number : 'N/A'}
              {hoveredCreditMemo === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.credit_memo_number || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredHotelTotal(row.original.id)}
            onMouseLeave={() => setHoveredHotelTotal(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Hotel Total:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <AttachMoney sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.hotel_cost ? row.original.hotel_cost : 'N/A'}
              {hoveredHotelTotal === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.hotel_cost || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredHotelCheckIn(row.original.id)}
            onMouseLeave={() => setHoveredHotelCheckIn(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Hotel Check In:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Today sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.hotel_check_in ? row.original.hotel_check_in : 'N/A'}
              {hoveredHotelCheckIn === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.hotel_check_in || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredHotelCheckOut(row.original.id)}
            onMouseLeave={() => setHoveredHotelCheckOut(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Hotel Check Out:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Event sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.hotel_check_out ? row.original.hotel_check_out : 'N/A'}
              {hoveredHotelCheckOut === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.hotel_check_out || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 2, pr: 6, pb: 1 }}
            onMouseEnter={() => setHoveredHotelNotes(row.original.id)}
            onMouseLeave={() => setHoveredHotelNotes(null)}
          >
            <Typography variant='body2' color='textSecondary'>
              Hotel Notes:
            </Typography>
            <Typography
              variant='body1'
              sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
            >
              <Notes sx={{ color: '#2471a3', mr: 1 }} />
              {row.original.hotel_notes ? row.original.hotel_notes : 'N/A'}
              {hoveredHotelNotes === row.original.id && (
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: -40,
                  }}
                  onClick={(e) => {
                    handleCopy(row.original.hotel_notes || 'N/A');
                  }}
                >
                  <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                </IconButton>
              )}
            </Typography>
          </Box>
        </Box>
        <InnerTable data={badges?.[row.original.a_id] ?? []} />
      </Box>
    ),
    muiTopToolbarProps: () => {
      return {
        sx: {
          '& .MuiBox-root': {
            alignItems: 'center',
          },
        },
      };
    },
    renderTopToolbarCustomActions: () => {
      const data = attendees.map((attendee) => {
        return {
          'Account #': attendee.account_number,
          Account: attendee.company_name,
          'Dealer Email': attendee.email_address,
          'Dealer Phone #': attendee.phone_number,
          Registered: attendee.registered === 'true' ? 'Y' : 'N',
          'Invite Code': attendee.invitation_code,
          'Credit Amount': attendee.credit_amount,
          'Credit Memo #': attendee.credit_memo_number,
          'Credit Memo Status': attendee.credit_status,
          Hotel: attendee.hotel_name,
          'Hotel Total': attendee.hotel_cost,
          'Hotel Check In': attendee.hotel_check_in,
          'Hotel Check Out': attendee.hotel_check_out,
          'Hotel Notes': attendee.hotel_notes,
          'Sales Rep': attendee.sales_rep,
        };
      });

      const componentList = [
        <ExcelExport data={data} fileName={`attendees-${dayjs().format('YYYY-MM-DD_HH-mm-ss')}`} />,
      ];

      if (stats) {
        Object.entries(stats)
          .filter(([key]) => !key.toLowerCase().includes('exhibitor'))
          .forEach(([key, value]) => {
            let color = 'inherit';
            if (key.toLowerCase().includes('yet to register')) {
              color = 'red';
            } else if (key.toLowerCase().includes('registered')) {
              color = 'green';
            }
            componentList.push(
              <Typography variant='body1'>
                {key}: <b style={{ color: color }}>{value}</b>
              </Typography>
            );
          });
      } else {
        componentList.push(<Skeleton sx={{ flexGrow: 1 }} />);
      }

      return (
        <>
          {isMobile ? (
            <MobileList componentList={componentList} />
          ) : (
            componentList.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))
          )}
        </>
      );
    },
    renderRowActions: ({ row }) => {
      const handleApplyCredit = () => {
        setSelectedAttendee(row.original);
      };
      return (
        <Box>
          {row.original.allow_credit !== 'false' && (
            <Button
              variant='contained'
              onClick={handleApplyCredit}
              disabled={row.original.allow_credit === 'disabled'}
              startIcon={<Add />}
            >
              Credit
            </Button>
          )}
        </Box>
      );
    },
    muiTableBodyRowProps: ({ isDetailPanel, row, table, staticRowIndex }) => {
      if (isDetailPanel) {
        const paginationRowModel = table.getPaginationRowModel();
        const isNextRowExpanded =
          staticRowIndex < paginationRowModel.rows.length - 1 &&
          paginationRowModel.rows[staticRowIndex + 1].getIsExpanded();

        return {
          sx: {
            '& > .MuiTableCell-root': {
              position: 'relative',
              transitionProperty: 'border-width, border-color, border-top, padding',
              transitionDuration: '150ms, 150ms, 0s, 150ms',
              transitionTimingFunction: 'ease-in-out',
              borderLeft: 'solid 0px transparent',
              borderRight: 'solid 0px transparent',
              borderBottom: isNextRowExpanded
                ? 'solid 0px transparent !important'
                : 'solid 0px transparent',
              ...(row.getIsExpanded() && {
                border: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                borderTop: 'none',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                },
              }),
            },
          },
        };
      } else {
        return {
          sx: {
            '& > .MuiTableCell-root': {
              transitionProperty: 'border-width, border-color, border-bottom',
              transitionDuration: '150ms, 150ms, 0s',
              transitionTimingFunction: 'ease-in-out',
              borderTop: 'solid 0px transparent',
              borderLeft: 'solid 0px transparent',
              borderRight: 'solid 0px transparent',
              ...(row.getIsExpanded() && {
                borderTop: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                borderBottom: 'none',
              }),
            },
            '& > .MuiTableCell-root:first-of-type': {
              ...(row.getIsExpanded() && {
                borderLeft: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
              }),
            },
            '& > .MuiTableCell-root:last-of-type': {
              ...(row.getIsExpanded() && {
                borderRight: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
              }),
            },
          },
        };
      }
    },
    onPaginationChange: handleSetPagination,
    state: { pagination },
  });

  useEffect(() => {
    const visibleAttendees = table
      .getRowModel()
      .flatRows.filter((row) => row.original.a_id != null)
      .map((row) => row.original.a_id);
    getBadges(visibleAttendees);
  }, [table, pagination, getBadges]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          '& > .MuiPaper-root': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiTableContainer-root': {
              flexGrow: 1,
            },
          },
        }}
      >
        <MaterialReactTable table={table} />
      </Box>
      <CreditModal
        open={isModalOpen}
        onClose={handleCloseModal}
        hotels={hotels}
        hotelDays={hotelDays}
        onSave={submitCredit}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message='Copied to clipboard'
      />
    </>
  );
};

export default Attendees;
