import { DeleteForever } from '@mui/icons-material';
import { Button, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { deleteContracts } from '../../../../../_GlobalFunctions/contractFunctions';
import { useContractContext } from '../../../../../../Context/Contracts/ContractsDashboardContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DeleteContract = ({ item }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  const { fetchContractsPublications } = useContractContext();

  // LOCAL STATE
  const [open, setOpen] = useState(false);

  // FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteContract = async () => {
    await deleteContracts([item.ss_publication_contract_id]);
    await fetchContractsPublications();
    handleClose();
  };

  return (
    <>
      <IconButton variant='contained' onClick={handleClickOpen}>
        <DeleteForever sx={{ color: theme.palette.delete.main }} />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle
          sx={{
            color: 'white',
            fontWeight: 'bold',
            backgroundColor: theme.palette.delete.main,
            marginBottom: '16px',
          }}
        >{`Delete Contract # ${item.ss_publication_contract_id}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {`If YES, this will permanently delete the contract for ${item.entity}! `}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#ffe2dd' }}>
          <Button variant='outlined' color='error' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color='error' onClick={deleteContract}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteContract;
