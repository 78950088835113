import { Box, Button, Divider, styled, useTheme } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const coverData = [
  'https://cdn4-imgs.topmags.com/products/extras/8181-tactical-life-cover-2023-november-1-issue.jpg?auto=format&cs=strip&h=410&lossless=true&w=300&s=c4887e5bf19cf4a8fb7a5b693766c308',
  'https://cdn4-imgs.topmags.com/products/extras/8181-tactical-life-cover-2023-may-16-issue.jpg?auto=format&cs=strip&h=410&lossless=true&w=300&s=cddf394da3a54a5c6d729ba08d4d26ec',
  'https://cdn4-imgs.topmags.com/products/extras/8181-tactical-life-cover-2023-may-1-issue.jpg?auto=format&cs=strip&h=410&lossless=true&w=300&s=26930259e1820476cebd2b3da8c83b53',
];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const LeftSide = ({ contractId }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [coverIndex, setCoverIndex] = useState(0);

  return (
    <Box sx={{ width: '100%', maxWidth: '375px', backgroundColor: 'white' }}>
      <img
        style={{
          aspectRatio: '3/4',
          width: '175px',
          boxShadow: '2px 4px 8px rgb(0,0,0,.3)',
          display: 'block',
          margin: '0 auto',
        }}
        src={coverData[coverIndex]}
        alt='cover'
      />
      <Box
        sx={{ my: '16px', display: 'flex', gap: '16px', width: '100%', justifyContent: 'center' }}
      >
        {[...Array(coverData.length)].map((_, idx) => (
          <Box
            sx={{
              width: '50px',
              border: `solid 1px ${theme.palette.primary.main}`,
              backgroundColor: coverIndex === idx ? theme.palette.primary.main : 'transparent',
              height: '6px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            key={idx}
            onClick={() => setCoverIndex(idx)}
          ></Box>
        ))}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Box sx={{ flex: 1, paddingTop: '16px' }}>
          <Box
            sx={{
              width: '80%',
              aspectRatio: '3/4',
              backgroundColor: theme.palette.primary.main33,
              boxShadow: `2px 4px 8px ${theme.palette.primary.main}44`,
              backgroundImage: `url(${coverData[2]})`,
              backgroundSize: 'cover',
            }}
          ></Box>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'right' }}>
          <h2
            style={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              textAlign: 'ceneter',
            }}
          >
            Submit Artwork:
          </h2>
          <Button
            component='label'
            role={undefined}
            variant='contained'
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload files
            <VisuallyHiddenInput
              type='file'
              onChange={(event) => console.log(event.target.files)}
              multiple
            />
          </Button>
          <div style={{ marginTop: '4px', color: `${theme.palette.primary.main}99` }}>
            File Uploaded Successfully
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSide;
