import { Edit } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { getAllPublicationProducts } from '../../../../../_GlobalFunctions/publicationFunctions';
import { updateContractsProductId } from '../../../../../_GlobalFunctions/contractFunctions';
import { useContractContext } from '../../../../../../Context/Contracts/ContractsDashboardContext';

const EditContractsProduct = ({ item }) => {
  // CONTEXT STATE
  const { fetchContractsPublications } = useContractContext();
  // LOCAL STATE
  const [products, setProducts] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // FUNCTIONS
  const handleClick = (event) => {
    handleEditClick();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = async () => {
    try {
      const res = await getAllPublicationProducts();

      // Ensure res is an array
      if (!Array.isArray(res)) {
        console.error('Unexpected response format. Expected an array.');
        return;
      }

      // Filter products based on types
      const types = res.filter((e) => {
        // Ensure e.types is a valid string
        if (typeof e.types === 'string') {
          return e.types
            .split(',')
            .map(Number)
            .includes(item.ss_publication.ss_publication_type_id); // Convert to numbers and check
        }
        return false;
      });
      setProducts(types);
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };

  const handleItemClick = async (id, standard_price) => {
    const res = await updateContractsProductId(id, item.ss_publication_contract_id, standard_price);
    handleClose();
    res.affectedRows > 0 ? await fetchContractsPublications() : console.log('nothing happend');
  };

  return (
    <>
      <Button
        variant='contained'
        endIcon={<Edit />}
        color='success'
        onClick={(e) => handleClick(e)}
      >
        {item.ss_publication_product.description}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {products.map((e, idx) => (
          <MenuItem
            key={idx}
            onClick={() => handleItemClick(e.ss_publication_product_id, e.standard_price)}
          >
            {e.description} - ${e.standard_price}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EditContractsProduct;
