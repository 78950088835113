import React from 'react';
import ManufacturerNavBar from './components/ManufacturerNavBar';

const ManufacturerLayout = ({ children }) => {
  return (
    <>
      <ManufacturerNavBar />
      {children}
    </>
  );
};

export default ManufacturerLayout;
