import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Typography, Grid2, styled, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from '../Context/ManufacturerContext';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
}));

const ManufacturerLineCharts = () => {
  const {
    axisX,
    axisY,
    categorySeries = [],
    pieFilter,
    topItems,
    monthFilter,
    setMonthFilter,
  } = useManufacturerContext();
  const [connectNulls, setConnectNulls] = useState(true);
  const [showMark, setShowMark] = useState(false);
  const theme = useTheme();
  const lineChartColors = [
    theme.palette.accent.main,
    theme.palette.primary.main, // Darkest Blue
    'rgba(40, 70, 100, 1)', // Less Saturated 1
    'rgba(80, 110, 140, 1)', // Less Saturated 2
    'rgba(120, 150, 180, 1)', // Less Saturated 3
  ];

  const totalSalesById = Object.keys(categorySeries[0] || {})
    .filter((key) => key !== 's_month') // Exclude 's_month'
    .map((key) => ({
      key,
      totalSales: categorySeries.reduce(
        (sum, row) => sum + (parseFloat(row[key]) || 0), // Sum sales for each ID
        0
      ),
    }))
    .sort((a, b) => b.totalSales - a.totalSales) // Sort in descending order of sales
    .slice(0, 5); // Limit to top 5 labels

  const totalTopItems = Object.keys(topItems[0] || {})
    .filter((key) => key !== 's_month')
    .map((key) => ({
      key,
      totalSales: topItems.reduce(
        (sum, row) => sum + (parseFloat(row[key]) || 0), // Sum sales for each ID
        0
      ),
    }))
    .sort((a, b) => b.totalSales - a.totalSales) // Sort in descending order of sales
    .slice(0, 5); // Limit to top 5 labels

  // Extract the top 5 keys and translate axisX to a number
  console.log('axisY', axisY.length);
  console.log('axisX', axisX.length);
  const seriesKeys = totalTopItems.map((item) => item.key);
  const seriesKeys2 = totalSalesById.map((item) => item.key);
  const numAxisX = axisX.map(Number);
  const monthName = numAxisX.map((month) =>
    new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(0, month - 1))
  );

  console.log(categorySeries);
  const catAxis = categorySeries.map((e) => e.s_month);
  console.log(catAxis);
  const monthName2 = catAxis.map((month) =>
    new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(0, month - 1))
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography
          align='center'
          sx={{
            fontWeight: 'bold',
            fontSize: '2em',
            color: theme.palette.primary.main,
          }}
        >
          Trending Sales for {pieFilter ? `${pieFilter.label}` : 'All Categories'}
        </Typography>
        {monthFilter && (
          <Button
            sx={{
              bgcolor: theme.palette.primary.main,
              color: '#ffffff',
              zIndex: 2,
            }}
            onClick={() => {
              setMonthFilter(null);
            }}
          >
            Reset Month Filter
          </Button>
        )}
      </Box>
      <Wrapper>
        <LineChart
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background for consistency
            borderRadius: '10px',
            padding: 1,
          }}
          grid={{
            vertical: true,
          }}
          xAxis={[
            {
              data: monthName,
              scaleType: 'point',
            },
          ]}
          series={[
            {
              data: axisY,
              color: theme.palette.accent.main, // Keep the accent color here
              label: pieFilter ? `${pieFilter.label}` : 'All Categories',
            },
          ]}
          onAxisClick={(event, chartClick) => setMonthFilter(chartClick)}
        />
      </Wrapper>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '2em',
          color: theme.palette.primary.main,
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        Top Items for {pieFilter ? `${pieFilter.label}` : 'All Categories'}
      </Typography>
      <Wrapper sx={{ display: 'flex', flexDirection: 'column' }}>
        <LineChart
          xAxis={[{ data: monthName, scaleType: 'point' }]}
          series={seriesKeys.map((key, index) => ({
            dataKey: key,
            label: key, // Label series by ID
            color: lineChartColors[index % lineChartColors.length], // Cycle through colors
            connectNulls, // Optional: Handle missing data
          }))}
          dataset={topItems} // Use the transformed data
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background
            borderRadius: '10px',
            padding: 1,
          }}
          grid={{
            vertical: true,
            style: { stroke: '#000000' }, // Grid styling
          }}
        />
      </Wrapper>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '2em',
          color: theme.palette.primary.main,
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        Sales By Category
      </Typography>
      <Wrapper sx={{ display: 'flex', flexDirection: 'column' }}>
        <LineChart
          xAxis={[{ data: monthName2, scaleType: 'point' }]}
          series={seriesKeys2.map((key, index) => ({
            dataKey: key,
            label: key, // Label series by ID
            color: lineChartColors[index % lineChartColors.length], // Cycle through colors
            connectNulls, // Optional: Handle missing data
          }))}
          dataset={categorySeries} // Use the transformed data
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background
            borderRadius: '10px',
            padding: 1,
          }}
          grid={{
            vertical: true,
            style: { stroke: '#000000' }, // Grid styling
          }}
        />
      </Wrapper>
    </>
  );
};

export default ManufacturerLineCharts;
