//http://localhost:3000/sandbox/cameron/function-test

import React from 'react';
import ManufacturerDashboard from './components/ManufacturerDashboard';
import { ManufacturerProvider } from './Context/ManufacturerContext';
import TextBoxTest from './components/TextBoxTest';
import CreditFormExport from '../../../../Deposits/components/CreditFormExport';

const FunctionTest = () => {
  //todo
  //check on bug with glock triggers erroring the page...
  //fix formatting for mobile
  //add ability to select multiple categories from the piechart filter
  //fix pagination/ pagesizeoptions

  //need as400 connection for node
  //replace all models with as400 connection

  return (
    <ManufacturerProvider>
      <ManufacturerDashboard />
      <CreditFormExport />
    </ManufacturerProvider>
  );
};

export default FunctionTest;
