import React from 'react';
import { Column, Container, Img, Link, Row, Section, Text } from '@react-email/components';

const GlobalFooterDisclosure = () => {
  return (
    <>
      <Section style={{ backgroundColor: '#022642', padding: '32px 16px' }}>
        <Row
          style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0px auto',
          }}
        >
          <Column
            style={{
              width: '100%',
              display: 'inline-block',
              color: 'white',
              textAlign: 'center',
            }}
          >
            Copyright © 2024 Sports South, LLC, All rights reserved. Want to change how you receive
            these emails? You can update your preferences or unsubscribe from this list.
          </Column>
        </Row>
      </Section>
    </>
  );
};

export default GlobalFooterDisclosure;
