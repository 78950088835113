import React from 'react';
import { Box, TextField, Paper, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from '../Context/ManufacturerContext';

const ManufacturerTable = () => {
  const { pieFilter, salesByItem, monthFilter } = useManufacturerContext();
  const theme = useTheme();

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (value) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
  };

  const columns = [
    { field: 'id', headerName: 'SKU', flex: 1.5 },
    {
      field: 'sales',
      headerName: 'Sales',
      flex: 0.5,
      ...usdPrice,
    },
    { field: 'category', headerName: 'Category', flex: 0.5 },
  ];

  const rows = salesByItem.map((item) => ({
    id: item.as400_description, // SKU
    sales: item.sales, // Raw sales value to use for sorting
    category: item.category, // Category
  }));

  return (
    <>
      <Box
        sx={{
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 'bold',
            fontSize: '2em',
            color: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          {pieFilter ? `${pieFilter.label}` : 'All Items'}
          {monthFilter ? ` for ${monthFilter.axisValue}` : ''}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          padding: '24px',
          height: 868,
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[25]}
          sx={{ border: 0 }}
          sortingOrder={['asc', 'desc']}
          initialState={{
            sorting: {
              sortModel: [{ field: 'sales', sort: 'desc' }],
            },
          }}
        />
      </Box>
    </>
  );
};

export default ManufacturerTable;
