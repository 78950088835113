import { Column, Img, Link, Row, Section, Text } from '@react-email/components';
import React from 'react';
import Header1000 from './Header1000';

const Block1010 = ({ block }) => {
  return (
    <Section style={{ marginTop: 16, marginBottom: 16 }}>
      <Section>
        <Row>
          <Text
            style={{
              margin: '0px',
              fontSize: 16,
              lineHeight: '24px',
              fontWeight: 600,
              color: 'rgb(79,70,229)',
            }}
          >
            {block.sub_header}
          </Text>
          <Text
            style={{
              margin: '0px',
              marginTop: 8,
              fontSize: 24,
              lineHeight: '32px',
              fontWeight: 600,
              color: 'rgb(17,24,39)',
            }}
          >
            {block.header}
          </Text>
          <Text
            style={{
              marginTop: 8,
              fontSize: 16,
              lineHeight: '24px',
              color: 'rgb(107,114,128)',
            }}
          >
            {block.text}
          </Text>
        </Row>
      </Section>
      <Section>
        {/* <Row>
          {block.products.map((product, idx) => (
            <Column key={idx} style={{ width: '33.333333%', paddingRight: 8 }}>
              <Link
                href={`https://www.theshootingwarehouse.com/?search_text=(${product})`}
                target='_blank'
              >
                <Img
                  alt='Stagg Electric Kettle'
                  height={186}
                  src={`https://media.server.theshootingwarehouse.com/hires/${product}.png`}
                  style={{
                    width: '100%',
                    borderRadius: 12,
                    objectFit: 'cover',
                  }}
                />
              </Link>
            </Column>
          ))} */}

        {Array.from({ length: Math.ceil(block.products.length / 3) }).map((_, idx) => (
          <Row key={idx}>
            {[...Array(3)].map(
              (item, i) =>
                block.products[idx * 3 + i] !== undefined && (
                  <Column key={i} style={{ width: '33.333333%', paddingRight: 8 }}>
                    <Link href='#'>
                      <Img
                        alt='Stagg Electric Kettle'
                        height={186}
                        src={`https://media.server.theshootingwarehouse.com/large/${
                          block.products[idx * 3 + i]
                        }.jpg`}
                        style={{
                          width: '100%',
                          borderRadius: 12,
                          objectFit: 'contain',
                        }}
                      />
                    </Link>
                  </Column>
                )
            )}
          </Row>
        ))}

        {/* <Column style={{ width: '33.333333%', paddingRight: 8 }}>
            <Link href='#'>
              <Img
                alt='Stagg Electric Kettle'
                height={186}
                src='https://react.email/static/stagg-eletric-kettle.jpg'
                style={{
                  width: '100%',
                  borderRadius: 12,
                  objectFit: 'cover',
                }}
              />
            </Link>
          </Column>
          <Column style={{ width: '33.333333%', paddingLeft: 8, paddingRight: 8 }}>
            <Link href='#'>
              <Img
                alt='Ode Grinder'
                height={186}
                src='https://react.email/static/ode-grinder.jpg'
                style={{
                  width: '100%',
                  borderRadius: 12,
                  objectFit: 'cover',
                }}
              />
            </Link>
          </Column>
          <Column style={{ width: '33.333333%', paddingLeft: 8 }}>
            <Link href='#'>
              <Img
                alt='Clyde Electric Kettle'
                height={186}
                src='https://react.email/static/clyde-electric-kettle.jpg'
                style={{
                  width: '100%',
                  borderRadius: 12,
                  objectFit: 'cover',
                }}
              />
            </Link>
          </Column> */}
        {/* </Row> */}
      </Section>
    </Section>
  );
};

export default Block1010;
