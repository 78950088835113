import axios from 'axios';
import dayjs from 'dayjs';

// DEPOSITS
export const getDeposits = async () => {
  const res = await axios.get(`/deposits/getDeposits`);
  return res;
};

// PAYMENTS
export const makePayment = async (data) => {
  const res = await axios.post(`/payments/makePayment`, {
    data: data,
  });

  return res.data;
};

export const getPaymentsPrimaryIdentifier = async (id, type) => {
  const res = await axios.get(`/payments/getPaymentsPrimaryIdentifier/${id}/${type}`);
  return res.data;
};

export const getContractsRemainingBalance = async (id, type, contract_amount) => {
  try {
    const res = await getPaymentsPrimaryIdentifier(id, type);
    console.log('res getContractsRemainingBalance: ', res);
    const values = res.map((e) => Number(e.payment_amount));
    const total = values.length > 0 ? values.reduce((sum, value) => sum + value, 0) : 0;
    return Number(contract_amount) - Number(total);
  } catch (err) {
    console.log('err: ', err);
  }
};

export const setContractsPaidStatus = async (id, status) => {
  const res = await axios.post(`/payments/setContractsPaidStatus`, {
    ss_publication_contract_id: id,
    ss_publication_payment_status_id: status,
  });
  return res.data;
};

export const insertCreditDeposit = async (depositDetails = {}) => {
  //required values for insert statement (depositDetails)
  // {deposit_amount,
  // description,
  // entity_deposit_method_id,
  // entity_deposit_type_id,
  // entity_id,
  // notes,
  // user_id,}
  const data = await axios.post(`/entities/insertCreditDeposit`, depositDetails);
  return data.data;
};
