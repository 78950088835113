import axios from 'axios';
import dayjs from 'dayjs';

export const getEntityDepositMethods = async () => {
  const data = await axios.get('/entities/getEntityDepositMethods');
  return data.data;
};

export const getEntityDepositTypes = async () => {
  const data = await axios.get('/entities/getEntityDepositTypes');
  return data.data;
};

export const getAllEntities = async () => {
  const data = await axios.get('/entities/getAllEntities');
  return data.data;
};

export const getEntitiesIdByUserId = async (userId) => {
  const data = await axios.get(`/entities/getEntitiesIdByUserId/${userId}`);
  return data.data;
};

// entity_deposits
// entity_payment_statuses
// entity_payment_types
// entities
