import { Box, Button, Modal, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getContractCreditMemo } from '../../../../../../_GlobalFunctions/contractFunctions';
import ContractAvailableFunds from '../../../../../../Contracts/components/ContractPayment/components/ContractAvailableFunds';
import { getContractsRemainingBalance } from '../../../../../../_GlobalFunctions/accountingFunctions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const PaymentStatus = ({ item, label }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);

  const [memoData, setMemoData] = useState([]);
  const [data, setData] = useState({});

  const [open, setOpen] = React.useState(false);

  // FUNCTIONS
  const handleClose = (res) => {
    setOpen(false);
    console.log('res on close of modal: ', res);
    res.affectedRows > 0 && alert(`All's good.... ${res.InsertId}`);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  useEffect(() => {
    setLoading(true);

    const func = async () => {
      const remainingAmount = await getContractsRemainingBalance(
        item.ss_publication_contract_id,
        'MARKETING_CONTRACT',
        item.sold_price
      );
      console.log('remainingAmount: ' + remainingAmount);
      const getMemoURL = await getContractCreditMemo(item.ss_publication_contract_id);

      setMemoData([getMemoURL]);
      setData({
        contractId: item.ss_publication_contract_id,
        status: item.ss_publication_payment_status_id,
        memoURL: await getContractCreditMemo(item.ss_publication_contract_id),
        remainingAmount: remainingAmount,
      });
      setLoading(false);
    };
    func();
  }, [item]);

  if (loading) {
    return <div>...loading</div>;
  }

  console.log('item sent to payment status: ', item);

  return (
    <>
      {data.status === 1 ? (
        <Button
          size='small'
          sx={{
            fontSize: '.7em',
            color:
              data.status === 1
                ? theme.palette.delete.main
                : data.status === 2
                ? theme.palette.save.main
                : theme.palette.accent.main,

            backgroundColor:
              data.status === 1 ? '#ffe2dd' : data.status === 2 ? '#e2ffe4' : '#fff1d1',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            textShadow: '1px 1px 0px rgba(0,0,0,.3)',
            letterSpacing: '.1em',
          }}
          onClick={(e) => handleOpen(e)}
        >
          {data.status === 1 ? 'Make Payment' : data.status === 2 ? 'Paid' : 'Pending'}
        </Button>
      ) : (
        <Box
          sx={{ color: data.status === 2 ? theme.palette.save.main : theme.palette.accent.main }}
        >
          {data.status === 1 ? 'Make Payment' : data.status === 2 ? 'Paid' : 'Pending'}
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ContractAvailableFunds
            user_id={54155}
            primary_identifier={item.ss_publication_contract_id}
            contract_amount={item.sold_price}
            handleModalClose={handleClose}
            entity_id={item.entity_id}
          />
        </Box>
      </Modal>
      {/* <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '.MuiMenu-list': { padding: 0 } }}
      >
        <Box sx={{ padding: '8px 24px 16px 24px', boxSizing: 'border-box' }}>
          {data.status === 1 ? (
            <ContractCreditMemo contractId={data.contractId} />
          ) : (
            <ViewPayments payment={memoData} item={item} />
          )}
        </Box>
      </Menu> */}
    </>
  );
};

export default PaymentStatus;
