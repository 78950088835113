import React, { useState } from 'react';
import { PieChart, pieArcLabelClasses, legend } from '@mui/x-charts';
import { Box, Typography, Button } from '@mui/material';
import { useManufacturerContext } from '../Context/ManufacturerContext';
import { useTheme } from '@mui/material/styles';
import { ImTextColor } from 'react-icons/im';

const ManufacturerPie = () => {
  const { pieSeries = [], pieFilter, setPieFilter, monthFilter } = useManufacturerContext();
  const [count, setCount] = useState(0);
  const theme = useTheme();
  const [highlight, setHighlight] = useState();

  const pieChartColors = [
    theme.palette.primary.main,
    'rgba(50, 90, 120, 1)', // Less Saturated 1
    'rgba(75, 115, 145, 1)', // Less Saturated 2
    'rgba(120, 150, 180, 1)', // Less Saturated 3
    'rgba(160, 180, 200, 1)',
  ];

  const handleClick = (event, slice) => {
    const sliceIndex = slice.dataIndex;
    setPieFilter(pieSeries[sliceIndex]);
  };

  const pieData = pieSeries
    .sort((a, b) => b.value - a.value) // Sort by value descending
    .slice(0, 5); // Grab the top 5 items
  const labels = pieData.map((item) => item.label);
  const maxLength = Math.max(...labels.map((label) => label.length));
  const piePadding = maxLength < 20 ? 15 : -5;
  const pieMargin = maxLength < 20 ? 150 : 260;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '2em',
            color: theme.palette.primary.main,
          }}
        >
          Top Categories {monthFilter ? `for ${monthFilter.axisValue}` : ''}
        </Typography>
        {pieFilter && (
          <Button
            sx={{
              bgcolor: theme.palette.primary.main,
              color: '#ffffff',
              zIndex: 2,
            }}
            onClick={() => {
              setPieFilter(null);
            }}
          >
            Reset Category Filter
          </Button>
        )}
      </Box>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          padding: '24px',
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <PieChart
          margin={{ top: 1, bottom: 0, left: 0, right: pieMargin }}
          sx={{
            zIndex: 1,
            '.MuiPieArc-root': { stroke: theme.palette.accent.main, strokeWidth: 2 },
            '.MuiPieArcLabel-root': { fill: 'white', fontSize: '1.2em' },
          }}
          colors={pieChartColors}
          series={[
            {
              data: pieData,
              arcLabel: (item) => `$${item.value.toLocaleString()}`,
              arcLabelMinAngle: 35,
              arcLabelRadius: '55%',
              highlightScope: { fade: 'global', highlight: 'item' },
            },
          ]}
          height={350}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: piePadding,
            },
          }}
          onItemClick={handleClick}
        />
      </Box>
    </>
  );
};
export default ManufacturerPie;
