import { Chip, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ItemsBrands = ({ brands }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [chips, setChips] = useState([]);
  const [deleted, setDeleted] = useState([]);

  // FUNCTIONS
  const handleDelete = (e) => {
    const chip = e;
    setDeleted((prev) => [...prev, e.ss_publication_contract_brand_id]);
    console.log('chip id: ', chip);
  };

  useEffect(() => {}, []);
  console.log('brands: ', brands);
  return (
    <>
      {brands
        .filter((brand) => !deleted.includes(brand.ss_publication_contract_brand_id))
        .map((e, i) => (
          <Chip
            key={i}
            label={e.brand}
            onDelete={() => handleDelete(e)}
            sx={{
              fontWeight: 'bold',
              fontSize: '.8em',
              color: theme.palette.primary.main,
              backgroundColor: `${theme.palette.primary.main}22`,
              '& .MuiChip-deleteIcon': {
                color: theme.palette.delete.main,
              },
            }}
          />
        ))}
    </>
  );
};

export default ItemsBrands;
