import React from 'react';
import { Box, Button, MenuItem, InputLabel, Select, Typography } from '@mui/material';
import { useManufacturerContext } from '../Context/ManufacturerContext';

const ManufacturerSelect = () => {
  const { manufacturers, loading, handleChange, selectedValue } = useManufacturerContext();

  return (
    <Box sx={{ display: 'flex', marginLeft: 2, marginTop: 2.5, marginBottom: -10 }}>
      <Select
        labelId='dropdown-label'
        id='dropdown'
        value={selectedValue}
        onChange={handleChange}
        autoWidth
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem',
        }}
      >
        <MenuItem value='' color='#ffffff'>
          <em>None</em>
        </MenuItem>
        {manufacturers.map((manufacturer, index) => (
          <MenuItem key={index} value={manufacturer.description}>
            {manufacturer.description}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default ManufacturerSelect;
